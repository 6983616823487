import React from 'react'

const Paragraph = ({para}) => {
  return (
    <>
        <p className="text-black-200 dark:text-white-100 w-10/12 sm:w-6/12 mt-6 md:mt-8 text-center opacity-80 tracking-wider md:tracking-wide text-base lg:text-lg leading-6 lg:leading-7">{para}</p>
    </>
  )
}

export default Paragraph