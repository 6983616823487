import React from "react";
import BorderMagicButton from "./ui/magic-buttton";
import appdevelopment from "../assets/app.jpg";
import webdevelopment from "../assets/web.webp";
import blockchain from "../assets/Blockchain.png";
import artificial from "../assets/ai.jpg";
import iot from "../assets/iot.webp";
import nft from "../assets/nft.png";
import { Link } from "react-router-dom";
import Paragraph from "./ui/Paragraph";
import Heading from "./ui/Heading";
import { useState } from 'react';
import { IoMdSend } from "react-icons/io";


const services = {
  'Web Development': {
    description: 'Our web development services cover everything from creating complex web applications to building informative lead-generation websites. We deliver powerful web solutions using the right mix of front-end and back-end technologies, ensuring a seamless and engaging user experience.',
    image: webdevelopment,
    path: '/webdevelopment'
  },
  'App Development': {
    description: 'Our app development services focus on creating robust mobile applications tailored to meet your business needs. We specialize in both native and cross-platform app development, providing solutions that enhance user engagement and drive business growth.',
    image: appdevelopment,
    path: '/appdevelopment'
  },
  'Artificial Intelligence': {
    description: 'We offer comprehensive AI services to drive innovation and efficiency in your business. Our AI solutions include machine learning, natural language processing, and predictive analytics, helping you harness the power of data to make informed decisions and automate processes.',
    image: artificial,
    path: '/artificialintelligence'
  },
  'NFT': {
    description: 'Our NFT services encompass the creation, management, and marketing of non-fungible tokens. We help artists, creators, and businesses tap into the burgeoning NFT market by providing end-to-end solutions for minting, selling, and trading digital assets securely and efficiently.',
    image: nft,
    path: '/nft'
  },
  'IOT': {
    description: 'Our IoT solutions connect and automate your devices, transforming them into smart, interconnected systems. We offer services ranging from device integration and data analytics to IoT platform development, enabling you to improve operational efficiency and create new business opportunities.',
    image: iot,
    path: '/iot'
  },
  'Blockchain': {
    description: 'Our blockchain services provide secure and transparent solutions for various industries. We specialize in blockchain development, smart contract implementation, and decentralized applications, helping you leverage the benefits of distributed ledger technology to enhance security and trust.',
    image: blockchain,
    path: '/blockchain'
  },
};

const Service = () => {
  const [selectedService, setSelectedService] = useState('Web Development');

  return (
    <div className="md:px-20 md:my-24 my-0 px-6 w-full">
      <div className="flex justify-center items-center flex-col">
        <Heading first="Our" second="Services" third="" />
        <Paragraph para="Over 5 years of business-centric tech expertise" />
      </div>
      <div className="flex flex-col lg:flex-row mt-8 md:mt-12 gap-8 px-4 md:px-20 mx-auto">
        <div className="w-full lg:w-1/4">
          <ul>
            {Object.keys(services).map((service) => (
              <li key={service}>
                <button
                  onClick={() => setSelectedService(service)}
                  className={`block w-full text-left element dark:text-white-100  text-[#333333] font-semibold py-2 px-4 border-l-4 ${
                    selectedService === service
                      ? 'border-blue-500 dark:border-zinc-100 text-blue-500 dark:bg-blue-800 '
                      : 'border-transparent font-medium text-[#333333] hover:dark:bg-blue-800 hover:dark:bg-opacity-20 hover:bg-blue-200'
                  }`}
                >
                  {service}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex element lg:w-5/12 flex-col lg:justify-start lg:items-start justify-center items-center">
          <h2 className="text-3xl element font-bold dark:text-white-100 text-[#333333]">{selectedService}</h2>
          <p className="mt-4 md:text-start element tracking-wide md:tracking-wider text-center w-full text-lg dark:text-white-100 text-[#333333]">{services[selectedService].description}</p>
        </div>
        <div className="flex justify-center element md:gap-4 gap-2 flex-col items-center lg:w-1/3 w-full  lg:pl-6">
          <img src={services[selectedService].image} alt={selectedService} className="rounded-2xl w-96 lg:w-full h-auto" />
          <div className="w-full">
            <Link to={'/service' + services[selectedService].path}>
              <BorderMagicButton
                title="Know more"
                // icon={<IoMdSend />}
                position="right"
                otherClasses="w-full md:2xl mt-0 md:mt-0 text-lg sm:text-xl font-normal"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
