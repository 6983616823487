import React from "react";
import Header from "./Header";
import { useParams } from "react-router-dom";
import { services } from "../data/service";
import {
  Cost,
  Expertise,
  Faq,
  Heading,
  Process,
  Projects,
  Tech,
  UseCases,
} from "./ui/ServiceComponents";
import Callback from "./Callback";
import Footer from "./Footer";

const DetailedInfo = () => {
  const { id } = useParams();

  const serviceInfo = services[id];

  const {
    head,
    techStack,
    projects,
    process,
    costFactors,
    faqs,
    servicesOffered,
    footer,
    useCasesData,
  } = serviceInfo;

  window.scrollTo(0,0)

  return (
    <main className="relative  dark:bg-black-100 bg-white  flex justify-center items-center flex-col overflow-hidden mx-auto">
      <Header />
      <div className="text-white-100 my-8 w-full">
        <Callback/>
        <Heading data={head} />
        <Expertise servicesOffered={servicesOffered} />
        <Process process={process} />
        <Cost costFactors={costFactors} />
        <Tech techStack={techStack} />
        <Faq faqs={faqs} />
        <Projects projects={projects} />
        <UseCases useCasesData={useCasesData} />
        <Footer footer={footer} />
      </div>
    </main>
  );
};

export default DetailedInfo;