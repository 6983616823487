import React from 'react'
import Callback from "./Callback";
import Clients from "./Clients";
import Experience from "./Experience";
import { Feature } from "./Feature";
import Footer from "./Footer";
import Hero from "./Hero";
import OfficeLocations from "./OfficeLocations";
import Service from "./Service";
import Stack from "./Stack";
import Helmet from "react-helmet";
import VirtueTechLogo from '../assets/Favicon.png';

const PageLayout = () => {

  const footer = {
    ftitle: "Digital presence to the next level?",
    desc: "Reach out to us today and let's discuss how we can help you achieve your goals."
  }


  return (
    <main className="relative  bg-white dark:bg-black-100 flex justify-center items-center flex-col overflow-hidden mx-auto">
      <div className="w-full">
        <Helmet>
          <title>Virtue Tech - Transforming Visions into Digital Experiences</title>
          <meta name="description" content="Welcome to Virtue Tech Services. We provide straightforward solutions to complex business challenges. Connect with our experts today!" />
          <meta name="keywords" content="Virtue Tech, digital transformation, business solutions, technology services" />
          <meta property="og:title" content="Virtue Tech - Transforming Visions into Digital Experiences" />
          <meta property="og:description" content="Welcome to Virtue Tech Services. We provide straightforward solutions to complex business challenges. Connect with our experts today!" />
          <meta property="og:image" content={VirtueTechLogo} />
          <meta property="og:url" content="https://virtuetech.co.in/" />
          <meta name="twitter:title" content="Virtue Tech - Transforming Visions into Digital Experiences" />
          <meta name="twitter:description" content="Welcome to VirtueTech Services. We provide straightforward solutions to complex business challenges. Connect with our experts today!" />
          <meta name="twitter:image" content={VirtueTechLogo} />
          <link rel="canonical" href="https://virtuetech.co.in/" />
        </Helmet>
        <Hero />
        <Feature />
        <Service />
        <Experience />
        <Stack />
        <Callback />
        <Footer footer={footer} />
      </div>
    </main>
  )
}

export default PageLayout;
