import { FaFacebook, FaLinkedin, FaLocationArrow } from "react-icons/fa6";
import footerGrid from "../assets/footer-grid.svg";
import insta from "../assets/insta.svg";
import twit from "../assets/twit.svg";
import MagicButton from "../components/ui/magic-buttton";
import Taipei from "../assets/Taipai.png";
import Chennai from "../assets/Chennai.png";

const Footer = ({ footer }) => {
  const { ftitle, desc } = footer;
  return (
    <footer
      className="w-full relative  md:mt-36  mt-14 md:px-20 lg:px-28 px-6 pb-10"
    >
      <div className="w-full dark:block hidden absolute left-0 -bottom-72 min-h-96">
        <img
          src={footerGrid}
          alt="grid"
          className="w-full h-full opacity-50 "
        />
      </div>

      <div className="flex flex-col  items-center">
        <h1 className="heading text-black-100 dark:text-white-200 lg:max-w-[45vw]">
          Ready to start <span className="text-[#1976D2] dark:text-blue-400">your </span>
          {ftitle}
        </h1>
        <p className="text-black-100 dark:text-white-200 md:mt-10 my-5 text-center">{desc}</p>
        <a href="mailto:admin@virtuetech.co.in" className="mt-4">
          <MagicButton
            title="Let's get in touch"
            icon={<FaLocationArrow />}
            position="right"
          />
        </a>
      </div>
      <div className="hidden lg:flex mt-16 flex-col lg:flex-row md:gap-6 gap-8 pt-8 justify-center md:justify-between items-center">

     
<div className="flex justify-center flex-col md:flex-row lg:w-[30%] items-center md:items-end">
        <img
          src={Chennai}
          alt="Taipei"
          className="w-56 fill-white md:w-40"
        />
        <div className="flex flex-col items-center md:items-start ">
          <h3 className="md:font-bold font-semibold text-black dark:text-white text-xl">
            India
          </h3>
          <p className="text-black-100 dark:text-white-100 pt-1 md:text-left text-center md:pt-0 w-full md:text-base text-sm md:tracking-wide tracking-wider">
            Chennai, IN
          </p>
        </div>
      </div>


      <div className="flex flex-col justify-center items-center gap-4">
       


        <div className="flex justify-center items-center flex-col">
          <div className="flex items-center  md:gap-3 gap-6">
            <div className="w-10 h-10 cursor-pointer flex justify-center items-center backdrop-filter backdrop-blur-lg saturate-180 bg-opacity-75 bg-black-200 rounded-lg border border-black-300">
              <a
                href="https://www.instagram.com/virtue_tech_services/"
                target="_blank"
              >
                <img src={insta} alt="instaIcon" width={20} height={20} />
              </a>
            </div>
          
            <div className="w-10 h-10 cursor-pointer flex justify-center items-center backdrop-filter backdrop-blur-lg saturate-180 bg-opacity-75 bg-black-200 rounded-lg border border-black-300 text-white">
              <a
                href="https://www.linkedin.com/company/virtue-tech/"
                target="_blank"
              >
                <FaLinkedin alt="linkedin"/>
              </a>
            </div>

            <div className="w-10 h-10 cursor-pointer flex justify-center items-center backdrop-filter backdrop-blur-lg saturate-180 bg-opacity-75 bg-black-200 rounded-lg border border-black-300 text-white">
              <a
                href="https://www.facebook.com/virtuetechservices"
                target="_blank"
              >
                <FaFacebook alt="facebook"/>
              </a>
            </div>

            <div className="w-10 h-10 cursor-pointer flex justify-center items-center backdrop-filter backdrop-blur-lg saturate-180 bg-opacity-75 bg-black-200 rounded-lg border border-black-300">
              <a
                href="https://x.com/_Virtue_Tech_"
                target="_blank"
              >
                <img src={twit} alt="xIcon" width={20} height={20} />
              </a>
            </div>
          </div>
        </div>
        <p className="md:text-base text-sm  text-black-100 dark:text-white-200 md:font-normal font-light">
          Copyright © 2024 Virtue Tech
        </p>
      </div>

      <div className="flex justify-end flex-col md:flex-row lg:w-[30%] items-center md:items-end">
        <img
          src={Taipei}
          alt="Taipei"
          className="w-56 fill-white md:w-40"
        />
        <div className="flex flex-col items-center md:items-start ">
          <h3 className="md:font-bold font-semibold text-black dark:text-white text-xl">
            TAIWAN
          </h3>
          <p className="text-black-100 dark:text-white-100 pt-1 md:text-left text-center md:pt-0 w-full md:text-base text-sm md:tracking-wide tracking-wider">
            Taipei, TW
          </p>
        </div>
      </div>
       
      </div>
      
      <div className="flex mt-16 lg:hidden flex-col lg:flex-row lg:gap-6 gap-14 pt-8 justify-center md:justify-between items-center">

     
<div className="flex justify-center flex-col md:flex-row pl-5 lg:pl-0 lg:w-[30%] items-center md:items-end">
        <img
          src={Chennai}
          alt="Chennai"
          className="w-56 fill-white md:w-40"
        />
        <div className="flex flex-col items-center md:items-start ">
          <h3 className="md:font-bold font-semibold text-black dark:text-white text-xl">
            INDIA
          </h3>
          <p className="text-black-100 dark:text-white-100 pt-1 md:text-left text-center md:pt-0 w-full md:text-base text-sm md:tracking-wide tracking-wider">
            Chennai, IN
          </p>
        </div>
      </div>


     

      <div className="flex justify-end flex-col md:flex-row lg:w-[30%] items-center md:items-end">
        <img
          src={Taipei}
          alt="Taipei"
          className="w-56 fill-white md:w-40"
        />
        <div className="flex flex-col items-center md:items-start ">
          <h3 className="md:font-bold font-semibold text-black dark:text-white text-xl">
            TAIWAN
          </h3>
          <p className="text-black-100 dark:text-white-100 pt-1 md:text-left text-center md:pt-0 w-full md:text-base text-sm md:tracking-wide tracking-wider">
            Taipei, TW
          </p>
        </div>
      </div>
       
      <div className="flex flex-col justify-center items-center gap-4">
       


       <div className="flex justify-center items-center flex-col">
         <div className="flex items-center  md:gap-3 gap-6">
           <div className="w-10 h-10 cursor-pointer flex justify-center items-center backdrop-filter backdrop-blur-lg saturate-180 bg-opacity-75 bg-black-200 rounded-lg border border-black-300">
             <a
               href="https://www.instagram.com/virtue_tech_services/"
               target="_blank"
             >
               <img src={insta} alt="instaIcon" width={20} height={20} />
             </a>
           </div>
         
           <div className="w-10 h-10 cursor-pointer flex justify-center items-center backdrop-filter backdrop-blur-lg saturate-180 bg-opacity-75 bg-black-200 rounded-lg border border-black-300 text-white">
             <a
               href="https://www.linkedin.com/company/virtue-tech/"
               target="_blank"
             >
               <FaLinkedin alt="linkedin"/>
             </a>
           </div>

           <div className="w-10 h-10 cursor-pointer flex justify-center items-center backdrop-filter backdrop-blur-lg saturate-180 bg-opacity-75 bg-black-200 rounded-lg border border-black-300 text-white">
             <a
               href="https://www.facebook.com/virtuetechservices"
               target="_blank"
             >
               <FaFacebook alt="facebook"/>
             </a>
           </div>

           <div className="w-10 h-10 cursor-pointer flex justify-center items-center backdrop-filter backdrop-blur-lg saturate-180 bg-opacity-75 bg-black-200 rounded-lg border border-black-300">
             <a
               href="https://x.com/_Virtue_Tech_"
               target="_blank"
             >
               <img src={twit} alt="xIcon" width={20} height={20} />
             </a>
           </div>
         </div>
       </div>
       <p className="md:text-base text-sm tracking-widest text-black-100 dark:text-white-200 md:font-normal font-light">
         Copyright © 2024 Virtue Tech
       </p>
     </div>

      </div>
      
    </footer>
  );
};

export default Footer;
