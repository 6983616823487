import React from "react";
import { useEffect } from "react";
import { motion, stagger, useAnimate } from "framer-motion";
import { cn } from "../../utils/cn";
/**
 *  UI: border magic from tailwind css btns
 *  Link: https://ui.aceternity.com/components/tailwindcss-buttons
 *
 *  change border radius to rounded-lg
 *  add margin of md:mt-10
 *  remove focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50
 */
const MagicButton = ({
  title,
  icon,
  position,
  handleClick,
  otherClasses,
}) => {
  return (
    <button
      className={`relative h-9 md:h-14 sm:h-12 w-full justify-center items-center flex gap-4 overflow-hidden rounded-full p-[1px] focus:outline-none ${otherClasses}`}
      onClick={handleClick} 
    >
      <span className="absolute  inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />

      {/* remove px-3 py-1, add px-5 gap-2 */}
      <span
        className={`inline-flex  bg-black-100  h-full w-full cursor-pointer items-center justify-center rounded-full
              px-7  text-sm font-medium text-white backdrop-blur-3xl gap-2 ${otherClasses}`}
      >
       
        {title}
        <span>
        { icon}
        </span>
      </span>
     
      
    </button>
  );
};

export default MagicButton;
