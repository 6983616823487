import React from "react";
import {  FaWhatsapp } from "react-icons/fa6";

const Callback = () => {
  return (
    <a
      href="https://wa.me/message/MIAXWLRFXA3HO1"
      className=" fixed -left-20  hidden sm:block text-black-100 -rotate-90 bottom-48"
      target="_blank"
      style={{ zIndex: 9999 }}
    >
      {/* bg-[#8BC34A] - green */}
      {/* 8f1b9a - pink */}
      <div className="">
        <div className=" bg-[#42A5F5] dark:bg-blue-400 rounded-b-md cursor-pointer px-3 pr-8 pt-10 py-2 lg:px-4 lg:pr-12 lg:pt-6 lg:py-3">
          <span className="sm:text-base lg:text-lg font-bold tracking-wide ">
            Connect with Us
          </span>
          <div className="absolute  bg-white-100 cursor-pointer rotate-90 border-2 border-black-100  rounded-full p-2 lg:p-3 -bottom-3 -right-3">
            <FaWhatsapp className="sm:text-2xl animate-pulse lg:text-3xl" />
          </div>
        </div>
      </div>
    </a>
  );
};

export default Callback;
