import React from "react";

const ServiceHeading = ({ first, second, third }) => {
  return (
    <>
      <h1 className="text-3xl md:tracking-normal tracking-wide sm:text-5xl lg:text-6xl text-[#333333] dark:text-white font-bold inter-var text-center">
      <span className="text-[#1976D2]   dark:text-blue-400">{first} </span>
        {second}
        <span className="text-[#1976D2]   dark:text-blue-400"> {third} </span>
      </h1>
    </>
  );
};

export default ServiceHeading;
