import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import PageLayout from "./components/PageLayout";
import DetailedInfo from "./components/DetailedInfo";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { ThemeContext, ThemeProvider } from "./components/ThemeContext";

const AppLayout = () => {
  const { isLight } = useContext(ThemeContext);
  return (
    <div className={isLight ? "light" : "dark"}>
      <Outlet />
    </div>
  );
};

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <PageLayout />,
    children: [
      {
        path: "/",
        element: <PageLayout />,
      },
      {
        path: "/service/:id",
        element: <DetailedInfo />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <RouterProvider router={appRouter} />
      <SpeedInsights />
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
