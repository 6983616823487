import React, { useContext } from "react";
import DarkLogo from "../assets/color logo.png";
import LightLogo from "../assets/light logo.png";

import { Link } from "react-router-dom";
import { FaMoon, FaSun } from "react-icons/fa6";
import { ThemeContext } from "./ThemeContext";

const Header = () => {
  const { isLight, handleMode } = useContext(ThemeContext);

  return (
    <>
      <div className="flex z-50 px-8 md:px-20 py-4 md:py-6 absolute left-0 top-0 justify-between items-center flex-row w-full">
        <Link className="w-full" to={"/"}>
          {isLight ? (
            <img
              src={LightLogo}
              className="w-32 element md:w-48"
              alt="VirtueTech Logo"
            ></img>
          ) : (
            <img
              src={DarkLogo}
              className="w-32 element md:w-48"
              alt="VirtueTech Logo"
            ></img>
          )}
        </Link>
        <div className={isLight ? "text-black" : "dark:text-white"}>
          <div className="text-xl sm:text-2xl md:text-3xl cursor-pointer element">
            {isLight ? (
              <FaSun onClick={handleMode} />
            ) : (
              <FaMoon onClick={handleMode} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
