import React from "react";

const ServicePara = ({para}) => {
  return (
    <>
      <p className="text-black-100 dark:text-white-100 w-11/12 sm:w-9/12 mt-4 md:mt-6 text-center opacity-80 tracking-wide md:tracking-widest text-base lg:text-lg leading-6 lg:leading-7">
        {para}
      </p>
    </>
  );
};

export default ServicePara;
