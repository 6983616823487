import Accordian from "./Accordian";
import { Button } from "./moving-borders";
import BorderMagicButton from "./magic-buttton";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoMdSend } from "react-icons/io";
import { Helmet } from "react-helmet";
import ServiceHeading from "./ServiceHeading";
import ServicePara from "./ServicePara";
import { FaArrowLeft, FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";


export const Expertise = ({ servicesOffered }) => {
  return (
    <div className="flex justify-center  md:px-20 lg:px-28 md:my-28 my-16 px-6 items-center flex-col">
      <div className="flex justify-center mb-12 md:mb-20 items-center flex-col ">
        <ServiceHeading
          first={servicesOffered?.servicesOfferedTitle}
          second={"Expertise"}
          third={""}
        />
        <ServicePara para={servicesOffered?.servicesOfferedPara} />
      </div>
      <div className="flex justify-center items-center flex-row flex-wrap gap-3 md:gap-6">
        {servicesOffered?.servicesOfferedData.map((service, index) => (
          <ServiceCard key={index} title={service.title} icon={service.icon} />
        ))}
      </div>
    </div>
  );
};

export const Process = ({ process }) => {
  return (
    <div className="flex md:my-28 my-8 justify-center items-center lg:justify-between lg:items-start gap-12 lg:gap-4 px-6 lg:px-28 md:px-20  flex-col lg:flex-row mx-auto p-4">
      <div className=" w-full lg:w-5/12">
        <h2 className="sm:text-5xl text-3xl text-[#333333]  dark:text-white tracking-wide md:tracking-normal lg:text-7xl font-bold">
          {process?.mainTitle}
        </h2>
        <p className="text-base text-[#333333]  dark:text-white-100 lg:w-10/12 tracking-wide lg:text-lg mt-3 lg:mt-6">
          {process?.desc}
        </p>
      </div>
      <div className="flex w-full lg:w-7/12 flex-col lg:flex-col">
        {process.steps.map((step) => (
          <div
            key={step.number}
            className="flex relative flex-row   justify-start items-center w-full p-2 lg:p-4"
          >
            <div className="flex items-center text-base lg:text-2xl left-0 absolute justify-center lg:w-24 w-12 h-12 lg:h-24 bg-blue-700 border border-blue-400 text-white rounded-full mb-4">
              {step.number}
            </div>
            <div className=" flex justify-start bg-blue-100 dark:bg-black-200 rounded-s-full w-full lg:rounded-s-full px-3 lg:px-4 pl-12 lg:pl-28 py-4 lg:py-6 items-start flex-col text-left">
              <h3 className="text-xl lg:text-2xl text-blue-600 dark:text-blue-400 font-semibold mb-1.5 lg:mb-2">
                {step.title}
              </h3>
              <p className="text-sm sm:font-normal dark:text-white-200 text-black-100 font-extralight sm:text-sm  lg:text-base tracking-wider sm:tracking-wider lg:tracking-wide text-left">
                {step.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const Cost = ({ costFactors }) => {
  return (
    <div className="flex justify-center md:px-20   md:my-28 my-16 px-6 items-center flex-col">
      <div className="flex justify-center mb-12 md:mb-20 items-center flex-col ">
        <ServiceHeading
          first={costFactors?.serviceName}
          second={"Cost"}
          third={"Factors"}
        />
        <ServicePara para={costFactors?.costDesc} />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
        {costFactors?.costFactorsItems.map((factor, index) => (
          <CostFactorCard
            key={index}
            title={factor.title}
            icon={factor.icon}
            description={factor.description}
          />
        ))}
      </div>
      <div className="text-center flex justify-center items-center mt-4 md:mt-8">
        <ServicePara
          para={
            "We help tailor expenses to your particular financial capacities and avoid hidden costs due to well-adjusted processes."
          }
        />{" "}
      </div>
    </div>
  );
};

export const Tech = ({ techStack }) => {
  return (
    <div className="flex justify-center md:px-20 md:my-28 my-16 px-6 items-center flex-col">
      <div className="flex justify-center mb-12 md:mb-20 items-center flex-col ">
        <ServiceHeading first={techStack?.serviceName} second={"Stack"} />
        <ServicePara para={techStack?.techdesc} />
      </div>
      <div className="flex justify-start items-start flex-col gap-6">
        <div className="flex justify-start items-start flex-col">
          {/* <div className="text-blue-200 md:tracking-normal tracking-wide font-semibold text-2xl ">Technologics</div> */}
          <div className="flex justify-center  md:gap-8 gap-4 items-center flex-row flex-wrap">
            {techStack?.technologiesUsed?.map((techUsed) => (
              <Button
                // key={card.id}
                //   random duration will be fun , I think , may be not
                // duration={Math.floor(Math.random() * 10000) + 10000}
                duration={10000}
                borderRadius="1.75rem"
                style={{
                  //   add these two
                  //   you can generate the color from here https://cssgradient.io/
                  background: "rgb(4,7,29)",
                  backgroundColor:
                    "linear-gradient(90deg, rgba(4,7,29,1) 0%, rgba(12,14,35,1) 100%)",
                  // add this border radius to make it more rounded so that the moving border is more realistic
                  borderRadius: `calc(1.75rem* 0.96)`,
                }}
                // remove bg-white dark:bg-slate-900
                className="flex-1 text-black  dark:text-white border-neutral-200 dark:border-slate-800"
              >
                <div className="flex flex-row justify-center items-center ">
                  <div className="flex justify-center element hover:scale-110 cursor-default items-center p-3  md:p-4 lg:p-6">
                    <img
                      src={techUsed.thumbnail}
                      alt={techUsed.thumbnail}
                      className="lg:w-14 md:w-12 w-12"
                    />
                  </div>
                </div>
              </Button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Faq = ({ faqs }) => {
  return (
    <div className="text-black-100 dark:text-white-100 flex flex-col  md:px-20 lg:px-28 px-6 md:my-28 my-16 justify-center items-center mx-auto w-full md:w-11/12 lg:w-10/12">
      <div className="my-8 md:my-12">
        <ServiceHeading
          first={"Frequently"}
          second={"Asked"}
          third={"Questions"}
        />
      </div>

      <Accordian accordionData={faqs} />
    </div>
  );
};

export const Projects = ({ projects }) => {
  return (
    <div className="md:my-28 my-16 md:px-20 px-6 lg:px-28 container mx-auto">
      <div className="flex justify-center my-8 md:my-12 items-center flex-col ">
        <ServiceHeading first={projects?.title} />
      </div>
      <Slider {...settings}>
        {projects.offerings.map((service, index) => (
          <div
            key={index}
            className="flex flex-row  items-center justify-between"
          >
            <div className="flex justify-evenly items-start flex-col md:flex-row w-full">
              <div className="w-full md:w-6/12 lg:w-5/12  p-4">
                <h2 className="text-5xl lg:text-7xl font-bold text-blue-500 dark:text-blue-400">
                  {service.title}
                </h2>
                <p className="text-base dark:text-white-200 text-black-100 lg:w-10/12 tracking-wide lg:text-lg mt-3 lg:mt-6">
                  {service.description}
                </p>
                <div className="w-full md:w-10/12 lg:w-6/12 sm:w-10/12 md:mt-5 mt-3 lg:mt-6">
                  <a href="https://api.whatsapp.com/message/MIAXWLRFXA3HO1?autoload=1&app_absent=0" target="_blank">
                  <BorderMagicButton
                    title={service?.buttonText}
                    icon={<IoMdSend />}
                    position="right"
                    otherClasses="w-full  mt-0 md:mt-0 text-base sm:text-lg md:text-xl font-normal"
                  /></a>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-5/12  p-4">
                <img
                  src={service.imageUrl}
                  alt={service.title}
                  className="h-56 md:h-96 w-full object-cover rounded-lg shadow-lg"
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

 

export const UseCases = ({ useCasesData }) => {
  return (
    <div className="md:my-28 my-16 md:px-20 px-6 lg:px-28 container mx-auto">
      <div className="flex justify-center  my-8 md:my-12 items-center flex-col ">
        <ServiceHeading
          first={useCasesData?.title}
          second={"Use"}
          third={"Cases"}
        />
      </div>
      <div className="flex flex-col md:flex-row justify-around mb-12">
        <div className="flex-1 m-4">
          <h3 className="text-2xl dark:text-white-200 text-black-100 font-semibold mb-4 text-center">
            For Employees
          </h3>
          <div className="flex flex-col  items-center">
            {useCasesData.forEmployees.examples.map((item, index) => (
              <div
                key={index}
                className="bg-transparent flex justify-center items-center text-center border-blue-400  hover:bg-opacity-50 hover:bg-blue-700  hover:dark:bg-blue-950 cursor-default element bg-opacity-15 border p-3 md:p-6 rounded-full w-full mb-4 md:w-4/4 lg:w-3/4"
              >
                <p className="text-lg tracking-wide text-black-100 dark:text-white">
                  {item}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="flex-1 m-4">
          <h3 className="text-2xl dark:text-white-200 text-black-100 font-semibold mb-4 text-center">
            For Customers
          </h3>
          <div className="flex flex-col  items-center">
            {useCasesData.forCustomers.examples.map((item, index) => (
              <div
                key={index}
                className="bg-transparent flex justify-center items-center text-center border-blue-400  hover:bg-opacity-50 hover:bg-blue-700  hover:dark:bg-blue-950 cursor-default element bg-opacity-15 border p-3 md:p-6 rounded-full w-full mb-4 md:w-4/4 lg:w-3/4"
              >
                <p className="text-lg tracking-wide text-black-100 dark:text-white">
                  {item}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ServiceCard = ({ title, icon }) => (
  <div className="">
    <Button
      //   random duration will be fun , I think , may be not
      // duration={Math.floor(Math.random() * 10000) + 10000}
      duration={10000}
      borderRadius="1.75rem"
      style={{
        //   add these two
        //   you can generate the color from here https://cssgradient.io/
        background: "rgb(4,7,29)",
        backgroundColor:
          "linear-gradient(90deg, rgba(4,7,29,1) 0%, rgba(12,14,35,1) 100%)",
        // add this border radius to make it more rounded so that the moving border is more realistic
        borderRadius: `calc(1.75rem* 0.96)`,
      }}
      // remove bg-white dark:bg-slate-900
      className="flex-1 p-2 px-4 md:px-7 md:p-5  text-black  dark:text-white border-neutral-200 dark:border-slate-800"
    >
      <div className="flex justify-between md:gap-3 gap-1 items-center w-full flex-row">
        <div className="md:text-4xl text-xl mb-2">{icon}</div>
        <div className="text-left text-black-100 dark:text-white-100 md:text-base md:tracking-wide tracking-wider text-sm font-normal">
          {title}
        </div>
      </div>
    </Button>
  </div>
);

export const demo = ({ title, icon, description }) => (
  <div className="flex flex-col items-center p-4 border rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 ease-in-out">
    <div className="text-4xl mb-2">{icon}</div>
    <div className="text-center text-lg font-semibold mb-2">{title}</div>
    <div className="text-center text-sm">{description}</div>
  </div>
);

export const settings = {
  dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: true,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
    className="bg-white-100 rounded-full text-xl hidden md:block absolute lg:text-2xl font-semibold text-black-100 p-1.5 lg:p-3 cursor-pointer hover:bg-white-200 hover:bg-opacity-65 hover:scale-95 element sm:-left-14 lg:-left-20 top-40"
      onClick={onClick}
    >
      <FaArrowLeftLong/>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
    className="bg-white-100 rounded-full text-xl hidden md:block absolute lg:text-2xl font-semibold text-black-100 p-1.5 lg:p-3 cursor-pointer hover:bg-white-200 hover:bg-opacity-65 hover:scale-95 element sm:-right-14 lg:-right-20 top-40"
    onClick={onClick}

  >
    <FaArrowRightLong/>
  </div>
  );
}

export const CostFactorCard = ({ title, icon, description }) => (
  <div className="flex flex-col items-start justify-start gap-3 p-6 bg-blue-200 dark:bg-blue-950  bg-opacity-15 border border-blue-400 rounded-bl-3xl  rounded-tr-3xl  shadow-blue-400 hover:shadow-md transition-shadow duration-200 ease-in-out">
    <div className="text-2xl md:text-4xl mb-0.5 md:mb-2">{icon}</div>
    <div className="text-left text-black-100 dark:text-white-100 md:tracking-normal tracking-wide text-xl md:text-2xl font-semibold md:font-bold">
      {title}
    </div>
    <p className="text-black-200 dark:text-white-100 text-left opacity-80 tracking-wide md:tracking-widest text-base lg:text-lg leading-6 lg:leading-7">
      {description}
    </p>
  </div>
);

export const Heading = ({ data }) => {
  const { title, paragraph, slug, keywords } = data;
  return (
    <>
      <Helmet>
        <title>{`Virtue Tech - ${title}`}</title>
        <meta
          name="description"
          content={`Learn more about our ${title} services. ${paragraph}`}
        />
        <meta name="keywords" content={`Virtue Tech, ${title}, ${keywords}`} />
        <meta property="og:title" content={`Virtue Tech - ${title}`} />
        <meta
          property="og:description"
          content={`Learn more about our ${slug} services. ${paragraph}`}
        />
        {/* <meta property="og:image" content={image} /> */}
        <meta
          property="og:url"
          content={`https://www.virtuetech.com/services/${slug}`}
        />
        {/* <meta property="fb:app_id" content="YOUR_FACEBOOK_APP_ID" />
        <meta name="twitter:card" content="summary_large_image" /> */}
        <meta name="twitter:title" content={`Virtue Tech - ${title}`} />
        <meta
          name="twitter:description"
          content={`Learn more about our ${slug} services. ${paragraph}`}
        />
        {/* <meta name="twitter:image" content={service.image} /> */}
        <link
          rel="canonical"
          href={`https://www.virtuetech.com/services/${slug}`}
        />
      </Helmet>
    </>
  );
};
