"use client";

import { cn } from "../../utils/cn";
import React, { useEffect, useState, useRef } from "react";
import { Button } from "./moving-borders";

export const InfiniteStack = ({
  items,
  direction = "",
  speed = "slow",
  pauseOnHover = false,
  className,
}) => {
  const containerRef = useRef(null);
  const scrollerRef = useRef(null);

  useEffect(() => {
    addAnimation();
  }, []);

  const [start, setStart] = useState(false);

  function addAnimation() {
    if (containerRef.current && scrollerRef.current) {
      const scrollerContent = Array.from(scrollerRef.current.children);

      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        if (scrollerRef.current) {
          scrollerRef.current.appendChild(duplicatedItem);
        }
      });

      getDirection();
      getSpeed();
      setStart(true);
    }
  }

  const getDirection = () => {
    if (containerRef.current) {
      if (direction === "left") {
        containerRef.current.style.setProperty(
          "--animation-direction",
          "forwards"
        );
      } else {
        containerRef.current.style.setProperty(
          "--animation-direction",
          "reverse"
        );
      }
    }
  };

  const getSpeed = () => {
    if (containerRef.current) {
      if (speed === "fast") {
        containerRef.current.style.setProperty("--animation-duration", "180s");
      } else if (speed === "slow") {
        containerRef.current.style.setProperty("--animation-duration", "160s");
      } else {
        containerRef.current.style.setProperty("--animation-duration", "200s");
      }
    }
  };

  return (
    <div
      ref={containerRef}
      className={cn(
        // max-w-7xl to w-screen
        "scroller relative z-20 w-screen overflow-hidden  [mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)]",
        className
      )}
    >
      <ul
        ref={scrollerRef}
        className={cn(
          // change gap-16
          "flex min-w-full shrink-0 gap-2 md:gap-4 md:py-1.5 w-max flex-nowrap",
          start && "animate-scroll ",
          pauseOnHover && "hover:[animation-play-state:paused]"
        )}
      >
        {items.map((card, idx) => (
          <li
            key={idx}
          >
            <Button
              key={card.id}
              duration={10000}
              borderRadius="1.75rem"
              style={{
                background: "rgb(4,7,29)",
                backgroundColor:
                  "linear-gradient(90deg, rgba(4,7,29,1) 0%, rgba(12,14,35,1) 100%)",
                borderRadius: `calc(1.75rem* 0.96)`,
              }}
              className="flex-1  text-black rounded-md dark:text-white border-neutral-200 dark:border-slate-800"
            >
              <div className="flex flex-row justify-center element items-center ">
                <div className="flex justify-center items-center p-3  md:p-4 lg:p-5">
                  <img
                    src={card.thumbnail}
                    alt={card.thumbnail}
                    loading="lazy"
                    className="lg:w-16 lg:h-16 md:h-14 h-12 md:w-14 w-12"
                  />
                </div>
              </div>
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
};
