import React from 'react'

// 388E3C - green
// 9C27B0 - pupl
// FF7043 , FF6F00,FFA000- org
const Heading = ({first, second,third}) => {
  return (
    <>
    
        <h1 className="text-4xl  sm:text-6xl lg:text-7xl text-[#333333] dark:text-white font-bold inter-var text-center">
          {first}
          <span className="text-[#1976D2]   dark:text-blue-400"> {second} </span>
           {third}
        </h1>
    </>
  )
}

export default Heading