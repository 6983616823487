import React from "react";
import Quality from "../assets/Quality.svg";
import Reliability from "../assets/Reliability.svg";
import Flexibility from "../assets/Flexibility.svg";
import Competence from "../assets/Competence.svg";
import { ExpButton } from "./ui/expButton";
import Heading from "./ui/Heading";
import Paragraph from "./ui/Paragraph";
import circle from "../assets/BlueCircle.webp"
import DarkContainer from "./ui/DarkContainer";

const Experience = () => {
  const workExperience = [
    {
      id: 1,
      title: "Quality",
      desc: "We ensure exceptional quality through rigorous testing and refinement, exceeding expectations.",
      className: "md:col-span-2",
      thumbnail: Quality,
      circle: circle,
    },
    {
      id: 2,
      title: "Reliability",
      desc: "We deliver dependable, robust solutions with consistent support and minimal downtime.",
      className: "md:col-span-2",
      thumbnail: Reliability,
      circle: circle,
    },
    {
      id: 3,
      title: "Flexibility",
      desc: "We adapt and customize our innovative solutions to meet evolving client needs seamlessly.",
      className: "md:col-span-2",
      thumbnail: Flexibility,
      circle: circle,
    },
    {
      id: 4,
      title: "Competence",
      desc: "Our expert team leverages extensive expertise in cutting-edge technologies for innovative results.",
      className: "md:col-span-2",
      thumbnail: Competence,
      circle: circle,
    },
  ];

  
  const ExperienceCard = ({ thumbnail, title, desc }) => (
    <div className="flex flex-col md:flex-row justify-center  lg:w-5/12  w-full lg:items-center p-2  md:p-3  rounded-xl lg:p-5 gap-2">
              <div className="flex text-center  h-full w-full md:w-3/12 relative justify-center items-center mr-1.5 sm:mr-2 lg:mr-5">
                <img
                  src={thumbnail}
                  alt={title}
                  className="lg:w-28 z-10 w-28"
                />
              </div>
              <div className="boxShadow dark:bg-black-200 bg-[#fcfeff] rounded-xl pl-4  py-4">
                <h1 className="text-center md:text-start md:opacity-90 opacity-85 text-black-100 dark:text-white-100 text-xl md:text-2xl md:tracking-normal tracking-wide md:font-semibold font-medium">
                  {title}
                </h1>
                <p className="text-center md:text-start md:tracking-wide tracking-wider md:text-base font-light  text-black-200 dark:text-white-200  mt-1 md:mt-2 md:font-normal">
                  {desc}
                </p>
              </div>
            </div>
  );


  

  return (
    <div className="md:px-20  md:my-24 md:mt-36 mt-14 px-6  w-full">
      <div className="flex justify-center items-center flex-col ">
        <Heading first={"Why"} second={"Choose"} third={"Us"} />
        <Paragraph
          para={
            " Team passionate about delivering solutions that bridge the gap between bleeding-edge technologies and complicated user experiences.            "
          }
        />
      </div>

{/* <DarkContainer workExperience={workExperience}/> */}

      
<div className="w-full py-10 flex justify-between lg:justify-center items-center flex-row flex-wrap gap-4 md:gap-10">
{workExperience.map((card) => (
      <ExperienceCard
        key={card.id}
        thumbnail={card.thumbnail}
        title={card.title}
        desc={card.desc}
      />
    ))}
</div>
    </div>
  );
};

export default Experience;
