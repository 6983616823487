import Spotlight from "../components/ui/Spotlight";
import BorderMagicButton from "./ui/magic-buttton";
import { TextGenerateEffect } from "./ui/text-generate-effect";
import { IoMdSend } from "react-icons/io";
import Header from "./Header";
import stroke from "../assets/brushstroke.png";
import stroke1 from "../assets/stroke1.png";
const Hero = () => {
  return (
    <div className=" pt-28">
      <div>
        <Spotlight
          className="-top-40 -left-10 md:-left-32 md:-top-20 h-screen"
          fill="gray"
        />

        <Spotlight
          className="h-[80vh] w-[50vw] top-10 left-full"
          fill="purple"
        />
        <Spotlight className="left-80 top-28 h-[80vh] w-[50vw]" fill="blue" />
      </div>
      <div
        className="h-[120vh] w-full dark:bg-black-100 bg-white  dark:bg-grid-white/[0.04] bg-grid-black-100/[0.05]
       absolute top-0 left-0 flex items-center justify-center"
      >
        <div
          className="absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black-100
         bg-white  [mask-image:radial-gradient(ellipse_at_center,transparent_15%,black)]"
        />
      </div>
      <Header />
      <div className="flex justify-center relative my-10 md:my-20 z-10">
        <div className="max-w-[89vw] md:max-w-2xl lg:max-w-[60vw] flex flex-col items-center justify-center">
          <div className="relative flex justify-center items-center">
            <img
              src={stroke1}
              alt="Background"
              className="dark:brightness-85 brightness-95 w-full h-auto"
            />

            <h1 className="absolute text-zinc-950 opacity-75 top-1/2 w-full overflow-visible left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center md:text-3xl lg:text-3xl text-base sm:text-2xl font-bold md:font-black dark:text-zinc-950 ">
              Welcome to 
              <span className="text-white opacity-100 px-1.5 text-2xl md:text-4xl font-bold md:font-extrabold">
                Virtue Tech
              </span>
               Services
            </h1>
          </div>

          <div></div>

          <TextGenerateEffect
            words="Transforming Visions into Digital Experiences"
            className="text-center font-extrabold text-[40px] md:text-5xl lg:text-7xl"
          />

          <p className="text-center text-[#333333] dark:text-white-200 md:tracking-wider mb-4 text-sm md:text-lg lg:text-2xl">
            One-stop software development company to build world-class digital
            products with a team of design, development and strategy experts.
          </p>
          <a
            href="mailto:admin@virtuetech.co.in"
            target="_blank"
            className="mt-4"
          >
            <BorderMagicButton
              title="Connect with our experts"
              icon={<IoMdSend />}
              position="right"
              otherClasses="tracking-wider "
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
