"use client";
import React, { useRef } from "react";
import {
  motion,
  useAnimationFrame,
  useMotionTemplate,
  useMotionValue,
  useTransform,
} from "framer-motion";
import { cn } from "../../utils/cn";

export function ExpButton({
    borderRadius = "1.75rem",
    children,
    as: Component = "button",
    containerClassName,
    borderClassName,
    duration,
    className,
    ...otherProps
  }) {
    return (
      <Component
        className={cn(
          // remove h-16 w-40, add  md:col-span-2
          "bg-transparent  relative text-xl p-[1px] overflow-hidden md:col-span-2 md:row-span-1",
          containerClassName
        )}
        style={{
          borderRadius: borderRadius,
        }}
        {...otherProps}
      >
        <div
          className="absolute inset-0 rounde-[1.75rem]"
          style={{ borderRadius: `calc(${borderRadius} * 0.96)` }}
        >
          <MovingBorder duration={duration} rx="30%" ry="30%">
            <div
              className={cn(
                "h-8 w-60 opacity-[0.2] dark:block hidden bg-[radial-gradient(#CBACF9_40%,transparent_60%)]",
                borderClassName
              )}
            />
          </MovingBorder>
        </div>
        {/* d1e5d1 - green */}
        {/* E1BEE7- pnk */}

        <div
          className={cn(
            "relative bg-slate-900/[0.] border-0 dark:border dark:border-slate-800 backdrop-blur-xl text-white flex items-center justify-center w-full h-full text-sm dark:bg-transparent bg-[#F0F8FF] antialiased",
            className
          )}
          style={{
            borderRadius: `calc(${borderRadius} * 0.96)`,
          }}
        >
          {children}
        </div>
      </Component>
    );
  }
  
  export const MovingBorder = ({
    children,
    duration = 2000,
    rx,
    ry,
    ...otherProps
  }) => {
    const pathRef = useRef();
    const progress = useMotionValue(0);
  
    useAnimationFrame((time) => {
      const length = pathRef.current?.getTotalLength();
      if (length) {
        const pxPerMillisecond = length / duration;
        progress.set((time * pxPerMillisecond) % length);
      }
    });
  
    const x = useTransform(
      progress,
      (val) => pathRef.current?.getPointAtLength(val).x
    );
    const y = useTransform(
      progress,
      (val) => pathRef.current?.getPointAtLength(val).y
    );
  
    const transform = useMotionTemplate`translateX(${x}px) translateY(${y}px) translateX(-50%) translateY(-50%)`;
  
    return (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          className="absolute h-full w-full"
          width="100%"
          height="100%"
          {...otherProps}
        >
          <rect
            fill="none"
            width="100%"
            height="100%"
            rx={rx}
            ry={ry}
            ref={pathRef}
          />
        </svg>
        <motion.div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "inline-block",
            transform,
          }}
        >
          {children}
        </motion.div>
      </>
    );
  };