import amqp from '../assets/Lang/amqp.png';
import angular from '../assets/Lang/angular.webp';
import arduino from '../assets/Lang/arduino.png';
import aws from '../assets/Lang/aws.webp';
import azureIotHub from '../assets/Lang/azure-iot-hub.png';
import bigml from '../assets/Lang/bigml.png';
import binanceSmartChain from '../assets/Lang/binance-smart-chain.png';
import chainlink from '../assets/Lang/Chainlink.png';
import corda from '../assets/Lang/corda.png';
import cosmos from '../assets/Lang/cosmos.png';
import dotnet from '../assets/Lang/dotnet.svg';
import ethereum from '../assets/Lang/Ethereum.png';
import firebase from '../assets/Lang/firebase.png';
import flutter from '../assets/Lang/Flutter.png';
import ganache from '../assets/Lang/ganache.png';
import huggingFace from '../assets/Lang/hugging_face-icon.webp';
import hyperledger from '../assets/Lang/hyperledger.png';
import iconic from '../assets/Lang/iconic.webp';
import iotLora from '../assets/Lang/iot-lora.png';
import ipfs from '../assets/Lang/Ipfs.png';
import java from '../assets/Lang/java.webp';
import js from '../assets/Lang/js.webp';
import mqtt from '../assets/Lang/mqtt.png';
import nodejs from '../assets/Lang/NodeJS.png';
import keras from '../assets/Lang/keras.png';
import kotlin from '../assets/Lang/Kotlin.webp';
import jupyter from '../assets/Lang/jupyter.png';
import matlab from '../assets/Lang/Matlab.png';
import mongoDb from '../assets/Lang/mongoDb.webp';
import raspberryPi from '../assets/Lang/raspberry-pi.png';
import nodeRed from '../assets/Lang/node-red.webp';
import redis from '../assets/Lang/redis.webp';
import phonegap from '../assets/Lang/phonegap.png';
import php from '../assets/Lang/PHP.png';
import polygon from '../assets/Lang/polygon.png';
import python from '../assets/Lang/python.webp';
import pytorch from '../assets/Lang/pytorch';
import reactNative from '../assets/Lang/React-Native.png';
import react from '../assets/react.webp'
import scikitLearn from '../assets/Lang/scikit-learn.png';
import solana from '../assets/Lang/solana-sol.png';
import solidity from '../assets/Lang/solidity.png';
import sql from '../assets/Lang/sql.webp';
import swift from '../assets/Lang/Swift.png';
import tailwind from '../assets/Lang/tailwind.png';
import tensorflow from '../assets/Lang/tensorflow.png';
import tezos from '../assets/Lang/tezos.webp';
import thingsboard from '../assets/Lang/thingsboard.png';
import transferData from '../assets/Lang/transfer_data.webp';
import truffle from '../assets/Lang/truffle.png';
import web3js from '../assets/Lang/web3js.png';
import zigbee from '../assets/Lang/zigbee.png';
import azure from '../assets/Lang/microsoft-azure.png';
import googleCloud from '../assets/Lang/gcloud.webp';
import typescript from '../assets/Lang/type.webp';
import xamarin from '../assets/Lang/xamarin.png';

import HealthCareIot from '../assets/Projects/HealthCare.webp'
import SmartHomeIot from '../assets/Projects/SmartHome.png'
import IndustrialIot from '../assets/Projects/IndustrialIot.png'

import DigitalArt from '../assets/Projects/DigitalArt.jpg'
import Collectible from '../assets/Projects/Collectible.png'
import RealEstate from '../assets/Projects/RealEstate.avif'

import MachineLearning from '../assets/Projects/MachineLearningModels.jpg'
import NLP from '../assets/Projects/NLP.jpg'
import ComputerVision from '../assets/Projects/ComputerVision.jpg'

import Ecommerce from '../assets/Projects/Ecommerce.webp'
import HealthCare from '../assets/Projects/HealthCare.webp'
import OnlineLearning from '../assets/Projects/OnlineLearning.webp'

import CustomApp from '../assets/Projects/CustomApp.png'
import Enterprise from '../assets/Projects/Enterpriseapp.png'
import CrossPlatform from '../assets/Projects/CrossPlatformApps.jpg'

import Dapp from '../assets/Projects/dapps.webp'
import SmartContract from '../assets/Projects/SmartContracts.png'
import SupplyChain from '../assets/Projects/Supplychain.jpg'


export const services = {
    webdevelopment: {
        head: {
            title: "Web Development Services",
            paragraph: "Discover cutting-edge web solutions at VirtueTech. We specialize in crafting bespoke web applications and portals tailored to enhance your digital presence. From dynamic web apps to progressive web applications (PWAs), we empower businesses with innovative, customized solutions that drive growth and engagement.",
            keywords:"web development, custom web applications, dynamic web apps, progressive web applications, PWA, VirtueTech, web solutions, digital presence, business growth, innovative web development",
            slug:"webdevelopment",
        },
        projects: {
            title: "What We Can Build for You",
            offerings: [
                {
                    title: "E-commerce Platform",
                    description: "Develop a robust e-commerce platform with secure payment gateways, user-friendly interfaces, and scalability for future growth.",
                    imageUrl: Ecommerce,
                    buttonText: "Lets Build"
                },
                {
                    title: "Healthcare Management System",
                    description: "Build an efficient healthcare management system with patient portals, appointment scheduling, and secure data management.",
                    imageUrl: HealthCare,
                    buttonText: "Lets Build"
                },
                {
                    title: "Online Learning Platform",
                    description: "Design an interactive online learning platform with course management, live classes, and student progress tracking.",
                    imageUrl: OnlineLearning,
                    buttonText: "Lets Build"
                }
            ]
        },
        servicesOffered: {
            servicesOfferedTitle: "Website",
            servicesOfferedPara: "We utilize our extensive expertise in mobile development to create tailored mobile applications that effectively cater to various business requirements, ranging from enterprise-scale solutions to consumer-centric apps suitable for businesses of all sizes.",
            servicesOfferedData: [
                {
                    title: "Custom Web Applications",
                    icon: "💻",
                    description: "Development of tailored solutions for various business needs."
                },
                {
                    title: "Web Portals",
                    icon: "🌐",
                    description: "Creation of custom platforms with competitive features and integrations."
                },
                {
                    title: "Progressive Web Applications (PWAs)",
                    icon: "📱",
                    description: "Building responsive applications for cross-platform user experiences."
                },
                {
                    title: "SaaS Applications",
                    icon: "☁️",
                    description: "Development of scalable software as a service solutions."
                },
                {
                    title: "PaaS-based Applications",
                    icon: "🔧",
                    description: "Implementation of Platform as a Service solutions for easy scaling."
                },
                {
                    title: "E-commerce Solutions",
                    icon: "🛒",
                    description: "Building secure and scalable e-commerce platforms for online businesses."
                },
                {
                    title: "Content Management Systems (CMS)",
                    icon: "📝",
                    description: "Development and customization of CMS for easy content management."
                },
                {
                    title: "Enterprise Web Solutions",
                    icon: "🏢",
                    description: "Creating robust web solutions tailored to enterprise needs."
                },
                {
                    title: "API Development and Integration",
                    icon: "🔌",
                    description: "Development and integration of APIs for seamless data exchange."
                },
                {
                    title: "Web Performance Optimization",
                    icon: "⚡",
                    description: "Improving the speed and performance of web applications."
                },
                {
                    title: "UI/UX Design",
                    icon: "🎨",
                    description: "Designing intuitive and engaging user interfaces and experiences."
                },
                {
                    title: "SEO Optimization",
                    icon: "🔍",
                    description: "Optimizing websites for better search engine visibility."
                },
                {
                    title: "Responsive Web Design",
                    icon: "📱",
                    description: "Ensuring web applications look and perform well on all devices."
                },
                {
                    title: "Security Implementation",
                    icon: "🔒",
                    description: "Implementing best practices for web application security."
                },
                {
                    title: "Maintenance and Support",
                    icon: "🛠️",
                    description: "Providing ongoing support and maintenance for web applications."
                }
            ]
        },
        techStack: {
            serviceName: "Web Development",
            techdesc: "Our engineers apprehend your business requirements and help you choose the right technology for your solution.",
            technologiesUsed: [
                {
                    id: "1",
                    thumbnail: react
                },
                {
                    id: "2",
                    thumbnail: js
                },
                {
                    id: "3",
                    thumbnail: typescript
                },
                {
                    id: "4",
                    thumbnail: angular
                },
                {
                    id: "5",
                    thumbnail: java
                },
                {
                    id: "6",
                    thumbnail: dotnet
                },
                {
                    id: "7",
                    thumbnail: tailwind
                },
                {
                    id: "8",
                    thumbnail: php
                },
                {
                    id: "9",
                    thumbnail: nodejs
                },{
                    id: "11",
                    thumbnail: web3js
                },
                {
                    id: "12",
                    thumbnail: sql
                },
                {
                    id: "13",
                    thumbnail: redis
                },
               
                {
                    id: "15",
                    thumbnail: jupyter
                },
                {
                    id: "16",
                    thumbnail: mongoDb
                }                
            ],
            

        },
        useCasesData: {
            title: "Web Applications",
            forCustomers: {
                description: "Web apps for customers",
                examples: [
                    "B2B and B2C portals",
                    "Patient portals",
                    "Learning portals",
                    "and more"
                ]
            },
            forEmployees: {
                description: "Web apps for employees",
                examples: [
                    "Digital workplace platforms",
                    "Business process management tools",
                    "HR management platforms",
                    "Enterprise content management apps",
                    "and more"
                ]
            }
        },
        process: {
            shortTitle: "WEBSITE",
            mainTitle: "Development Process",
            desc: "From ideation to implementation, we'll bring your visions to life with secure and cutting-edge blockchain solutions. We write robust architectures with innovation and scalability into every line of code. We ensure flawless blockchain creations through rigorous testing and meticulous attention to detail",
            steps: [
                {
                    number: 1,
                    title: 'Requirement Gathering and Analysis',
                    description: 'We begin by thoroughly understanding your project\'s needs, ensuring that every requirement is captured and analyzed, setting the stage for a tailored blockchain solution.'
                },
                {
                    number: 2,
                    title: 'Design and Architecture',
                    description: 'Our experts design the blueprint for your blockchain solution, prioritizing scalability, security, and efficiency, creating a robust and future-ready framework.'
                },
                {
                    number: 3,
                    title: 'Integration and Testing',
                    description: 'Our skilled developers bring your blockchain solution to life, seamlessly integrating it with your existing systems and subjecting it to rigorous testing, ensuring a flawless performance.'
                }
            ],
        },
        costFactors: {
            serviceName: "Web Development",
            costDesc: "The ultimate cost of your web solution development project is defined by a number of factor",

            costFactorsItems: [
                {
                    title: "Cooperation model",
                    icon: "🤝", // Replace with appropriate icon
                    description: "The type of cooperation model (fixed price, time and material, dedicated team) can affect the overall cost."
                },
                {
                    title: "Scope of work",
                    icon: "📋", // Replace with appropriate icon
                    description: "The extent and complexity of the features and functionality required for your project."
                },
                {
                    title: "Project duration",
                    icon: "⏳", // Replace with appropriate icon
                    description: "The total time required to complete the project, from planning to deployment."
                },
                {
                    title: "Team size",
                    icon: "👥", // Replace with appropriate icon
                    description: "The number of professionals involved in the project, including developers, designers, and project managers."
                },
                {
                    title: "Team composition",
                    icon: "🛠️", // Replace with appropriate icon
                    description: "The specific roles and expertise of team members required to complete the project."
                },
                {
                    title: "Level of specialists",
                    icon: "⭐", // Replace with appropriate icon
                    description: "The experience and skill level of the specialists involved, which can influence the hourly rates."
                }
            ]
        },
        faqs: [
            {
                question: "What web app technologies do you work with?",
                answer: "At VirtueTech, we specialize in a wide range of web app technologies, including HTML, CSS, JavaScript, React, Angular, and Vue.js for frontend development. For backend solutions, we work with Node.js, Django, Ruby on Rails, PHP, and Java. Our goal is to leverage the latest technologies to ensure robust and scalable web applications tailored to meet your business needs."
            },
            {
                question: "Will the web app look good on all devices?",
                answer: "Yes, absolutely. We follow responsive design principles to ensure that your web application adapts seamlessly to various screen sizes and devices, including desktops, tablets, and mobile phones. Our team conducts thorough testing across different devices and browsers to deliver a consistent user experience."
            },
            {
                question: "How do you ensure the security of web applications?",
                answer: "Security is our top priority at VirtueTech. We implement best practices for web application security, including data encryption, secure authentication methods, and regular security audits. Additionally, we adhere to industry standards and guidelines to protect your web application from potential vulnerabilities."
            },
            {
                question: "Can you integrate third-party services into our web application?",
                answer: "Yes, we have extensive experience in integrating third-party services and APIs into web applications. Whether you need payment gateways, social media integrations, or CRM systems, our team can seamlessly integrate these services to enhance the functionality and user experience of your web application."
            },
            {
                question: "What is your approach to project management?",
                answer: "We follow agile project management methodologies to ensure transparency, collaboration, and efficient project delivery. Our team conducts regular sprint meetings, maintains clear communication channels, and provides regular updates to keep you informed about the project progress. We also prioritize feedback and strive for continuous improvement throughout the development lifecycle."
            }
        ],
        footer:{
            ftitle:"next web development project with us?",
         desc:"Reach out to us today and let's discuss how We an help you achieve your goals.",
        }
    },
    appdevelopment : {
        head: {
            title: "Custom App Development Services",
            paragraph: "Transform your business with VirtueTech's pioneering app development services. We create comprehensive mobile applications tailored to your needs, including iOS, Android, and cross-platform solutions. Our advanced team delivers secure, high-performance apps that drive user engagement and business growth.",
            keywords: "app development, mobile applications, iOS app development, Android app development, cross-platform apps, custom mobile apps, VirtueTech, user engagement, business growth, secure app development",
            slug: "appdevelopment",
        },
        
        projects: {
            title: "What We Can Build for You",
            offerings: [
                {
                    title: "Custom Mobile Apps",
                    description: "Create custom mobile applications tailored to your business needs, ensuring high performance and scalability.",
                    imageUrl: CustomApp,
                     buttonText: "Lets Build"
                },
                {
                    title: "Enterprise Apps",
                    description: "Develop enterprise-level applications that enhance productivity and streamline business processes.",
                    imageUrl: Enterprise,
                     buttonText: "Lets Build"
                },
                {
                    title: "Cross-platform Apps",
                    description: "Design and develop cross-platform applications that run seamlessly on multiple devices and operating systems.",
                    imageUrl: CrossPlatform,
                     buttonText: "Lets Build"
                }
            ]
        },
        servicesOffered: {
            servicesOfferedTitle: "Mobile App Development",
            servicesOfferedPara: "We offer end-to-end mobile app development services, from concept and design to development and deployment, tailored to meet your unique business needs.",
            servicesOfferedData: [
                {
                    title: "Native Mobile Apps",
                    icon: "📱",
                    description: "Development of native mobile apps for iOS and Android platforms."
                },
                {
                    title: "Cross-platform Apps",
                    icon: "🌐",
                    description: "Building cross-platform applications for multiple operating systems."
                },
                {
                    title: "Wearable Apps",
                    icon: "⌚",
                    description: "Creating innovative apps for wearable devices."
                },
                {
                    title: "Enterprise Mobile Solutions",
                    icon: "🏢",
                    description: "Developing scalable mobile solutions for enterprise needs."
                },
                {
                    title: "Mobile Game Development",
                    icon: "🎮",
                    description: "Designing and developing engaging mobile games."
                },
                {
                    title: "AR/VR Mobile Apps",
                    icon: "🌐",
                    description: "Creating augmented and virtual reality applications for immersive experiences."
                },
                {
                    title: "Mobile App Consulting",
                    icon: "💼",
                    description: "Providing expert consulting services for mobile app development."
                },
                {
                    title: "Mobile App Maintenance",
                    icon: "🛠️",
                    description: "Offering maintenance and support services for mobile applications."
                },
                {
                    title: "UI/UX Design for Mobile Apps",
                    icon: "🎨",
                    description: "Designing intuitive and user-friendly interfaces for mobile applications."
                },
                {
                    title: "Mobile App Testing",
                    icon: "🧪",
                    description: "Conducting comprehensive testing to ensure app functionality and performance."
                }
            ]
        },
        techStack: {
            serviceName: "App Development",
            techdesc: "Our skilled developers leverage the latest technologies to deliver robust and scalable mobile applications.",
            technologiesUsed: [
               
                    {
                        id: "1",
                        thumbnail: swift
                    },
                    {
                        id: "2",
                        thumbnail: kotlin
                    },
                    {
                        id: "3",
                        thumbnail: java
                    },
                    {
                        id: "4",
                        thumbnail: flutter
                    },
                    {
                        id: "5",
                        thumbnail: reactNative
                    },
                    {
                        id: "6",
                        thumbnail: xamarin
                    },
                    {
                        id: "9",
                        thumbnail: phonegap
                    },
                    {
                        id: "10",
                        thumbnail: firebase
                    },
                    {
                        id: "11",
                        thumbnail: aws
                    },
                    {
                        id: "12",
                        thumbnail: azure
                    },
                    {
                        id: "13",
                        thumbnail: googleCloud
                    },
            ],
        },
        useCasesData: {
            title: "Mobile Applications",
            forCustomers: {
                description: "Mobile apps for customers",
                examples: [
                    "Shopping apps",
                    "Fitness apps",
                    "Social media apps",
                    "and more"
                ]
            },
            forEmployees: {
                description: "Mobile apps for employees",
                examples: [
                    "Field service apps",
                    "Salesforce automation apps",
                    "Employee communication apps",
                    "and more"
                ]
            }
        },
        process: {
            shortTitle: "APPDEV",
            mainTitle: "Development Process",
            desc: "From ideation to launch, our app development process ensures the delivery of high-quality mobile applications that meet your business objectives.",
            steps: [
                {
                    number: 1,
                    title: 'Requirement Gathering and Analysis',
                    description: 'Understanding your app requirements and creating a detailed project plan.'
                },
                {
                    number: 2,
                    title: 'Design and Prototyping',
                    description: 'Designing user-friendly interfaces and creating prototypes for feedback.'
                },
                {
                    number: 3,
                    title: 'Development and Testing',
                    description: 'Building the app using the latest technologies and conducting rigorous testing.'
                },
                {
                    number: 4,
                    title: 'Deployment and Maintenance',
                    description: 'Deploying the app to app stores and providing ongoing maintenance and support.'
                }
            ],
        },
        costFactors: {
            serviceName: "App Development",
            costDesc: "The cost of app development is influenced by several factors.",
            costFactorsItems: [
                {
                    title: "App Complexity",
                    icon: "📋",
                    description: "The complexity of the app's features and functionality."
                },
                {
                    title: "Design Requirements",
                    icon: "🎨",
                    description: "The level of detail and customization in the app's design."
                },
                {
                    title: "Platform Choice",
                    icon: "📱",
                    description: "The number of platforms (iOS, Android) the app needs to support."
                },
                {
                    title: "Development Team",
                    icon: "👥",
                    description: "The size and expertise of the development team."
                },
                {
                    title: "Third-party Integrations",
                    icon: "🔌",
                    description: "The need for integrating third-party services and APIs."
                },
                {
                    title: "Maintenance and Updates",
                    icon: "🛠️",
                    description: "Ongoing maintenance and updates after the app's launch."
                }
            ]
        },
        faqs: [
            {
                question: "What platforms do you develop mobile apps for?",
                answer: "We develop mobile apps for iOS, Android, and cross-platform solutions using technologies like React Native and Flutter."
            },
            {
                question: "Can you assist with app store submission?",
                answer: "Yes, we offer full support for app store submission, including compliance with guidelines and optimization for visibility."
            },
            {
                question: "Do you provide post-launch support and maintenance?",
                answer: "Yes, we offer ongoing support and maintenance services to ensure your app remains up-to-date and performs optimally."
            },
            {
                question: "How do you ensure the security of mobile apps?",
                answer: "We implement best practices for mobile app security, including data encryption, secure authentication, and regular security audits."
            }
        ],
        footer:{
            ftitle:"next app development project with us?",
         desc:"Reach out to us today and let's discuss how We an help you achieve your goals.",
        }
    },
    blockchain : {
        head: {
            title: "Blockchain Development Services",
            paragraph: "Leverage blockchain with VirtueTech's innovative blockchain development services. We create secure, scalable, and customized blockchain solutions for your business. From smart contracts to decentralized applications (DApps), we deliver advanced blockchain technology that drives growth and efficiency.",
            keywords: "blockchain development, blockchain solutions, smart contracts, decentralized applications, DApps, secure blockchain, scalable blockchain, customized blockchain, VirtueTech, blockchain technology, business efficiency",
            slug: "blockchain",
        },
        
        projects: {
            title: "What We Can Build for You",
            offerings: [
                {
                    title: "Decentralized Applications (dApps)",
                    description: "Develop decentralized applications that leverage blockchain technology for enhanced security and transparency.",
                    imageUrl: Dapp,
                     buttonText: "Lets Build"
                },
                {
                    title: "Smart Contracts",
                    description: "Design and deploy smart contracts to automate business processes and ensure tamper-proof agreements.",
                    imageUrl: SmartContract,
                    buttonText: "Lets Build"
                },
                {
                    title: "Supply Chain Solutions",
                    description: "Implement blockchain-based supply chain solutions for enhanced traceability and transparency.",
                    imageUrl:SupplyChain ,
                     buttonText: "Lets Build"
                }
            ]
        },
        servicesOffered: {
            servicesOfferedTitle: "Blockchain Development",
            servicesOfferedPara: "We specialize in building blockchain solutions that transform industries by enhancing security, efficiency, and trust through decentralized applications and smart contracts.",
            servicesOfferedData: [
                {
                    title: "Smart Contract Development",
                    icon: "🔐",
                    description: "Design and deploy smart contracts to automate processes and ensure secure, transparent transactions."
                },
                {
                    title: "dApp Development",
                    icon: "📱",
                    description: "Create decentralized applications (dApps) to leverage blockchain technology for various use cases."
                },
                {
                    title: "Blockchain Consulting",
                    icon: "💼",
                    description: "Provide strategic guidance and consulting services for blockchain adoption and implementation."
                },
                {
                    title: "Supply Chain Blockchain Solutions",
                    icon: "📦",
                    description: "Implement blockchain solutions to enhance transparency and traceability in supply chain management."
                },
                {
                    title: "Blockchain Integration",
                    icon: "🔄",
                    description: "Integrate blockchain technology with existing systems to optimize processes and enhance security."
                },
                {
                    title: "Blockchain as a Service (BaaS)",
                    icon: "☁️",
                    description: "Offer blockchain infrastructure and services to businesses through scalable cloud solutions."
                },
                {
                    title: "Tokenization Solutions",
                    icon: "💱",
                    description: "Create tokenization solutions for digital assets, securities, and cryptocurrencies."
                },
                {
                    title: "Decentralized Finance (DeFi)",
                    icon: "💵",
                    description: "Develop decentralized finance applications for lending, borrowing, and trading."
                },
                {
                    title: "Blockchain Security Audits",
                    icon: "🛡️",
                    description: "Conduct security audits to identify and mitigate vulnerabilities in blockchain applications."
                }
            ]
        },
        techStack: {
            serviceName: "Blockchain Development",
            techdesc: "Our blockchain developers leverage leading technologies to build secure and scalable blockchain solutions tailored to your business needs.",
            technologiesUsed: [
                {
                    id: "1",
                    thumbnail: ethereum
                },
                {
                    id: "2",
                    thumbnail: hyperledger
                },
                {
                    id: "3",
                    thumbnail: solana
                },
                {
                    id: "4",
                    thumbnail: corda  // Note: Since 'path-to-cardano-logo' is not in the given imports, 'corda' is used as a placeholder.
                },
                {
                    id: "5",
                    thumbnail: binanceSmartChain
                },
                {
                    id: "6",
                    thumbnail: tezos
                },
                {
                    id: "7",
                    thumbnail: chainlink
                },
                {
                    id: "8",
                    thumbnail: cosmos
                },
                {
                    id: "9",
                    thumbnail: ganache
                },
                {
                    id: "10",
                    thumbnail: iconic
                },
                {
                    id: "11",
                    thumbnail: iotLora
                },
                {
                    id: "12",
                    thumbnail: solidity
                },
                {
                    id: "13",
                    thumbnail: truffle
                },
                {
                    id: "14",
                    thumbnail: web3js
                },
                {
                    id: "15",
                    thumbnail: zigbee
                }
                
            ],
            
        },
        useCasesData: {
            title: "Blockchain Applications",
            forCustomers: {
                description: "Blockchain applications for customers",
                examples: [
                    "Supply chain tracking",
                    "Tokenization platforms",
                    "Decentralized marketplaces",
                    "and more"
                ]
            },
            forEmployees: {
                description: "Blockchain applications for employees",
                examples: [
                    "Enterprise blockchain solutions",
                    "Digital identity management",
                    "Secure document verification",
                    "and more"
                ]
            }
        },
        process: {
            shortTitle: "BLOCKCHAIN",
            mainTitle: "Development Process",
            desc: "Our blockchain development process ensures the creation of secure and efficient blockchain solutions tailored to your business requirements.",
            steps: [
                {
                    number: 1,
                    title: 'Requirement Gathering and Analysis',
                    description: 'Understand your business needs and define the requirements for the blockchain solution.'
                },
                {
                    number: 2,
                    title: 'Architecture and Design',
                    description: 'Design the architecture and framework for the blockchain solution, ensuring scalability and security.'
                },
                {
                    number: 3,
                    title: 'Development and Testing',
                    description: 'Develop the blockchain solution using advanced technologies and conduct rigorous testing.'
                },
                {
                    number: 4,
                    title: 'Deployment and Maintenance',
                    description: 'Deploy the blockchain application and provide ongoing maintenance and support to ensure optimal performance.'
                }
            ],
        },
        costFactors: {
            serviceName: "Blockchain Development",
            costDesc: "The cost of blockchain development depends on various factors that influence the complexity and scale of the project.",
            costFactorsItems: [
                {
                    title: "Project Complexity",
                    icon: "🧩",
                    description: "The complexity of the blockchain solution and its technical requirements."
                },
                {
                    title: "Blockchain Platform",
                    icon: "🔗",
                    description: "The choice of blockchain platform (Ethereum, Hyperledger, etc.) and its capabilities."
                },
                {
                    title: "Smart Contract Development",
                    icon: "📜",
                    description: "The development and deployment of smart contracts for business automation."
                },
                {
                    title: "Security and Compliance",
                    icon: "🔒",
                    description: "Ensuring robust security measures and compliance with regulatory requirements."
                },
                {
                    title: "Scalability Requirements",
                    icon: "⚙️",
                    description: "The scalability needs of the blockchain solution as it grows."
                },
                {
                    title: "Integration Complexity",
                    icon: "🔌",
                    description: "The complexity of integrating blockchain with existing systems and services."
                },
                {
                    title: "Team Expertise",
                    icon: "👩‍💻",
                    description: "The expertise and experience of the blockchain development team."
                }
            ]
        },
        faqs: [
            {
                question: "What industries benefit from blockchain solutions?",
                answer: "Blockchain technology benefits industries such as finance, supply chain, healthcare, real estate, and more by enhancing transparency, security, and efficiency."
            },
            {
                question: "What blockchain platforms do you work with?",
                answer: "We specialize in developing blockchain solutions on platforms like Ethereum, Hyperledger, Solana, Cardano, Binance Smart Chain, and Tezos, tailoring solutions to meet specific business needs."
            },
            {
                question: "How do you ensure the security of blockchain applications?",
                answer: "Security is a top priority. We implement robust security measures, including encryption, multi-factor authentication, and regular security audits, to protect blockchain applications from vulnerabilities."
            },
            {
                question: "Can you develop smart contracts for my business?",
                answer: "Yes, we specialize in designing and deploying smart contracts tailored to automate business processes, ensuring secure and transparent transactions."
            },
            {
                question: "What is your approach to blockchain consulting?",
                answer: "We provide strategic blockchain consulting services to help businesses understand and leverage blockchain technology effectively. Our expertise includes feasibility analysis, implementation strategies, and regulatory compliance."
            }
        ],
        footer:{
            ftitle:"next blockchain development project with us?",
         desc:"Reach out to us today and let's discuss how We an help you achieve your goals.",
        }
    },  
    artificialintelligence : {
        head: {
            title: "AI Development Services",
            paragraph: "Revolutionize your business with VirtueTech's advanced AI development services. We offer comprehensive artificial intelligence solutions, including machine learning, natural language processing, and predictive analytics. Enhance your operations and decision-making with our innovative AI technologies.",
            keywords: "AI development, artificial intelligence, machine learning, natural language processing, predictive analytics, AI solutions, VirtueTech, business transformation, AI technologies, advanced AI",
            slug: "artificialdevelopment",
        },
        
        projects: {
            title: "What We Can Build for You",
            offerings: [
                {
                    title: "Machine Learning Models",
                    description: "Develop custom machine learning models to analyze data, make predictions, and automate processes.",
                    imageUrl: MachineLearning,
                    buttonText: "Let's Build"
                },
                {
                    title: "Natural Language Processing (NLP)",
                    description: "Create NLP models to analyze and derive insights from textual data, enabling smarter decision-making.",
                    imageUrl: NLP,
                     buttonText: "Let's Build"
                },
                {
                    title: "Computer Vision Solutions",
                    description: "Build computer vision models to analyze and interpret visual data for applications like image recognition and object detection.",
                    imageUrl: ComputerVision,
                     buttonText: "Let's Build"
                }
            ]
        },
        servicesOffered: {
            servicesOfferedTitle: "AI Development",
            servicesOfferedPara: "Our AI development services harness the power of machine learning and natural language processing to deliver intelligent solutions that transform businesses and enhance operational efficiency.",
            servicesOfferedData: [
                {
                    title: "Machine Learning Solutions",
                    icon: "🤖",
                    description: "Develop custom machine learning solutions to automate processes and extract insights from data."
                },
                {
                    title: "Natural Language Processing (NLP)",
                    icon: "🔤",
                    description: "Create NLP models to analyze and process textual data for sentiment analysis, language translation, and more."
                },
                {
                    title: "Computer Vision Applications",
                    icon: "👁️",
                    description: "Build computer vision models for image and video analysis, object detection, and visual data interpretation."
                },
                {
                    title: "AI Consulting Services",
                    icon: "📊",
                    description: "Provide strategic AI consulting to identify opportunities, develop AI strategies, and implement AI solutions."
                },
                {
                    title: "AI Integration",
                    icon: "🔄",
                    description: "Integrate AI capabilities into existing systems to enhance functionality and decision-making."
                },
                {
                    title: "Predictive Analytics",
                    icon: "📈",
                    description: "Develop predictive models to forecast trends, optimize processes, and improve business outcomes."
                },
                {
                    title: "AI-driven Automation",
                    icon: "⚙️",
                    description: "Automate repetitive tasks and streamline operations using AI-driven solutions and robotic process automation (RPA)."
                },
                {
                    title: "Speech Recognition Systems",
                    icon: "🎙️",
                    description: "Create speech recognition systems for voice-enabled applications and virtual assistants."
                },
                {
                    title: "AI-powered Personalization",
                    icon: "🌐",
                    description: "Deliver personalized user experiences and recommendations using AI algorithms and data analytics."
                }
            ]
        },
        techStack: {
            serviceName: "AI Development",
            techdesc: "Our AI developers leverage advanced technologies to build scalable and intelligent AI solutions tailored to meet your business objectives.",
            technologiesUsed: [
                {
                    id: "1",
                    thumbnail: python
                },
                {
                    id: "2",
                    thumbnail: tensorflow
                },
                {
                    id: "3",
                    thumbnail: pytorch
                },
                {
                    id: "4",
                    thumbnail: scikitLearn
                },
                {
                    id: "5",
                    thumbnail: keras
                },
                {
                    id: "6",
                    thumbnail: huggingFace  
                },
                {
                    id: "7",
                    thumbnail: bigml
                },
                {
                    id: "8",
                    thumbnail: jupyter
                },
                {
                    id: "9",
                    thumbnail: matlab
                }
                
            ],
            
        },
        useCasesData: {
            title: "AI Applications",
            forCustomers: {
                description: "AI applications for customers",
                examples: [
                    "Personalized recommendations",
                    "Predictive analytics",
                    "Virtual assistants",
                    "and more"
                ]
            },
            forEmployees: {
                description: "AI applications for employees",
                examples: [
                    "Workflow automation",
                    "Data analysis and insights",
                    "Natural language processing tools",
                    "and more"
                ]
            }
        },
        process: {
            shortTitle: "AI",
            mainTitle: "Development Process",
            desc: "Our AI development process focuses on leveraging machine learning and natural language processing to deliver intelligent solutions tailored to your business needs.",
            steps: [
                {
                    number: 1,
                    title: 'Discovery and Requirement Gathering',
                    description: 'Understand your business objectives and gather requirements for AI solution development.'
                },
                {
                    number: 2,
                    title: 'Model Training and Evaluation',
                    description: 'Train machine learning models and evaluate performance to ensure accuracy and reliability.'
                },
                {
                    number: 3,
                    title: 'Integration and Deployment',
                    description: 'Integrate AI models into applications and deploy solutions for real-world use.'
                },
                {
                    number: 4,
                    title: 'Monitoring and Optimization',
                    description: 'Monitor AI performance, collect feedback, and optimize models for continuous improvement.'
                }
            ],
        },
        costFactors: {
            serviceName: "AI Development",
            costDesc: "The cost of AI development depends on various factors that influence the complexity and scope of AI projects.",
            costFactorsItems: [
                {
                    title: "Complexity of AI Models",
                    icon: "🧠",
                    description: "The complexity of machine learning algorithms and AI models developed for specific use cases."
                },
                {
                    title: "Data Quality and Quantity",
                    icon: "📊",
                    description: "The quality and volume of data used to train AI models and ensure accurate predictions."
                },
                {
                    title: "AI Infrastructure",
                    icon: "🖥️",
                    description: "The infrastructure required for AI development, including computing resources and software tools."
                },
                {
                    title: "Integration with Existing Systems",
                    icon: "🔗",
                    description: "The complexity of integrating AI solutions with existing IT infrastructure and applications."
                },
                {
                    title: "AI Talent and Expertise",
                    icon: "👩‍💻",
                    description: "The expertise and experience of AI developers and data scientists involved in the project."
                },
                {
                    title: "Deployment and Maintenance",
                    icon: "🛠️",
                    description: "The cost of deploying AI solutions and providing ongoing maintenance and support."
                }
            ]
        },
        faqs: [
            {
                question: "What industries benefit from AI solutions?",
                answer: "AI solutions benefit industries such as healthcare, finance, retail, manufacturing, and more by optimizing operations, enhancing customer experiences, and driving innovation."
            },
            {
                question: "How do AI solutions improve business processes?",
                answer: "AI enhances business processes by automating tasks, analyzing data to derive insights, predicting trends, and personalizing customer interactions."
            },
            {
                question: "Can AI be integrated with existing business applications?",
                answer: "Yes, AI can be seamlessly integrated with existing business applications to enhance functionality, improve decision-making, and streamline operations."
            },
            {
                question: "What are the ethical considerations in AI development?",
                answer: "Ethical considerations in AI development include fairness, transparency, privacy protection, and bias mitigation to ensure responsible AI deployment."
            },
            {
                question: "How can AI help in predictive analytics?",
                answer: "AI algorithms analyze historical data to forecast trends, predict outcomes, and optimize business strategies for better decision-making."
            }
        ],
        footer:{
            ftitle:"next artificial intelligence development project with us?",
         desc:"Reach out to us today and let's discuss how We an help you achieve your goals.",
        }
    },  
    iot : {
        head: {
            title: "IoT Development Services",
            paragraph: "Unlock the potential of IoT with VirtueTech's innovative IoT development services. We create comprehensive connected solutions that enhance operational efficiency, provide real-time data insights, and improve user experiences. From smart devices to IoT platforms, our secure IoT solutions drive business success.",
            keywords: "IoT development, Internet of Things, connected solutions, smart devices, IoT platforms, real-time data, operational efficiency, user experience, VirtueTech, IoT solutions, business success",
            slug: "iot",
        },
        
        
        projects: {
            title: "What We Can Build for You",
            offerings: [
                {
                    title: "Smart Home Automation",
                    description: "Create custom solutions for smart home automation, enabling users to control devices remotely and optimize energy usage.",
                    imageUrl: SmartHomeIot,
                    buttonText: "Let's Build"
                },
                {
                    title: "Industrial IoT Solutions",
                    description: "Develop IoT solutions for industrial applications, optimizing processes, and enabling predictive maintenance.",
                    imageUrl: IndustrialIot,
                     buttonText: "Let's Build"
                },
                {
                    title: "Healthcare IoT Solutions",
                    description: "Design IoT solutions for healthcare applications, enabling remote patient monitoring and improving healthcare delivery.",
                    imageUrl: HealthCareIot,
                      buttonText: "Let's Build"
                }
            ]
        },
        servicesOffered: {
            servicesOfferedTitle: "IoT Development",
            servicesOfferedPara: "Our IoT development services leverage cutting-edge technologies to create smart, connected solutions that optimize operations, enhance user experiences, and drive business growth.",
            servicesOfferedData: [
                {
                    title: "Smart Device Integration",
                    icon: "🔌",
                    description: "Integrate smart devices and sensors to collect real-time data and enable remote monitoring and control."
                },
                {
                    title: "Predictive Maintenance",
                    icon: "⚙️",
                    description: "Implement predictive analytics to monitor equipment performance and schedule maintenance proactively."
                },
                {
                    title: "IoT Platform Development",
                    icon: "🌐",
                    description: "Build scalable IoT platforms to manage connected devices, data, and applications securely."
                },
                {
                    title: "IoT Security Solutions",
                    icon: "🔒",
                    description: "Ensure data privacy and device security with robust IoT security solutions and encryption protocols."
                },
                {
                    title: "Edge Computing Solutions",
                    icon: "🌐",
                    description: "Deploy edge computing solutions to process data closer to the source, reducing latency and enhancing efficiency."
                },
                {
                    title: "IoT Consulting Services",
                    icon: "📊",
                    description: "Provide strategic IoT consulting to design IoT strategies, evaluate technology options, and implement IoT solutions."
                },
                {
                    title: "Industrial IoT (IIoT) Solutions",
                    icon: "🏭",
                    description: "Develop IIoT solutions for industrial automation, predictive maintenance, and operational efficiency."
                },
                {
                    title: "IoT Application Development",
                    icon: "📱",
                    description: "Design and develop custom IoT applications tailored to specific industry and business requirements."
                }
            ]
        },
        techStack: {
            serviceName: "IoT Development",
            techdesc: "Our IoT developers utilize a wide range of technologies to build scalable and secure IoT solutions that drive digital transformation and operational excellence.",
            technologiesUsed : [
                {
                    id: "1",
                    thumbnail: amqp
                },
                {
                    id: "2",
                    thumbnail: aws
                },
                {
                    id: "3",
                    thumbnail: googleCloud
                },
                {
                    id: "4",
                    thumbnail: azureIotHub
                },
                {
                    id: "5",
                    thumbnail: azure
                },
                {
                    id: "6",
                    thumbnail: mqtt
                },
                {
                    id: "7",
                    thumbnail: thingsboard
                },
                {
                    id: "8",
                    thumbnail: transferData
                },
                {
                    id: "9",
                    thumbnail: arduino
                },
                {
                    id: "11",
                    thumbnail: ipfs
                },
                {
                    id: "13",
                    thumbnail: nodeRed
                },
                {
                    id: "14",
                    thumbnail: raspberryPi
                }
                
            ],
        },
        useCasesData: {
            title: "IoT Applications",
            forCustomers: {
                description: "IoT applications for customers",
                examples: [
                    "Smart home automation",
                    "Connected healthcare devices",
                    "Smart retail solutions",
                    "and more"
                ]
            },
            forEmployees: {
                description: "IoT applications for employees",
                examples: [
                    "Industrial automation",
                    "Remote monitoring and management",
                    "Asset tracking and management",
                    "and more"
                ]
            }
        },
        process: {
            shortTitle: "IoT",
            mainTitle: "Development Process",
            desc: "Our IoT development process focuses on creating scalable and secure IoT solutions that drive operational efficiency and innovation.",
            steps: [
                {
                    number: 1,
                    title: 'IoT Strategy and Planning',
                    description: 'Define IoT objectives, assess technology requirements, and develop a comprehensive IoT strategy.'
                },
                {
                    number: 2,
                    title: 'IoT Device Integration',
                    description: 'Integrate sensors, devices, and IoT platforms to collect and transmit data securely and efficiently.'
                },
                {
                    number: 3,
                    title: 'Data Analytics and Insights',
                    description: 'Analyze IoT data to derive actionable insights, optimize processes, and improve decision-making.'
                },
                {
                    number: 4,
                    title: 'Security and Compliance',
                    description: 'Implement robust security measures and ensure compliance with IoT data privacy regulations.'
                }
            ],
        },
        costFactors: {
            serviceName: "IoT Development",
            costDesc: "The cost of IoT development varies based on factors such as project complexity, technology stack, deployment scale, and ongoing maintenance.",
            costFactorsItems: [
                {
                    title: "IoT Device Complexity",
                    icon: "📱",
                    description: "The complexity and functionality of IoT devices and sensors used in the solution."
                },
                {
                    title: "Scale of Deployment",
                    icon: "🌐",
                    description: "The number of devices and the scale of IoT deployment across locations and environments."
                },
                {
                    title: "Data Management and Analytics",
                    icon: "📊",
                    description: "The complexity of data management, analytics capabilities, and integration with existing systems."
                },
                {
                    title: "Security and Compliance",
                    icon: "🔐",
                    description: "The implementation of robust security measures and compliance with IoT data protection regulations."
                },
                {
                    title: "IoT Platform Integration",
                    icon: "🌐",
                    description: "The integration of IoT platforms and cloud services for data storage, processing, and analytics."
                },
                {
                    title: "IoT Development Team",
                    icon: "👨‍💻",
                    description: "The expertise and experience of IoT developers and engineers involved in the project."
                }
            ]
        },
        faqs: [
            {
                question: "What are the benefits of IoT solutions?",
                answer: "IoT solutions enhance operational efficiency, enable real-time data monitoring, optimize resource management, and improve decision-making."
            },
            {
                question: "How can IoT transform industries?",
                answer: "IoT drives digital transformation across industries by enabling predictive maintenance, remote monitoring, and smart automation."
            },
            {
                question: "What industries can benefit from IoT?",
                answer: "Industries such as manufacturing, healthcare, logistics, agriculture, and smart cities can benefit from IoT solutions."
            },
            {
                question: "What are the challenges in IoT implementation?",
                answer: "Challenges include data security concerns, interoperability issues, scalability challenges, and integration complexities."
            },
            {
                question: "How can IoT improve customer experiences?",
                answer: "IoT enables personalized services, predictive recommendations, and seamless interactions, enhancing customer satisfaction."
            }
        ],
        footer:{
            ftitle:"next Iot project with us?",
         desc:"Reach out to us today and let's discuss how We an help you achieve your goals.",
        }
    },
    nft : {
        head: {
            title: "NFT Development Services",
            paragraph: "Embrace digital assets with VirtueTech's pioneering NFT development services. We create secure, scalable, and unique non-fungible tokens (NFTs) for various industries. Enhance your digital portfolio with our innovative NFT solutions.",
            keywords: "NFT development, non-fungible tokens, digital assets, secure NFTs, scalable NFTs, NFT solutions, VirtueTech, digital portfolio, NFT technology, unique NFTs",
            slug: "nft",
        },
        
        projects: {
            title: "What We Can Build for You",
            offerings: [
                {
                    title: "Digital Art Marketplace",
                    description: "Build a decentralized marketplace for digital art, enabling artists to tokenize and sell their creations as NFTs.",
                    imageUrl: DigitalArt,
                    buttonText: "Explore NFTs"
                },
                {
                    title: "Collectibles Platform",
                    description: "Create a platform for trading and collecting NFT-based digital collectibles, ensuring authenticity and ownership rights.",
                    imageUrl: Collectible,
                    buttonText: "Explore NFTs"
                },
                {
                    title: "Real Estate Tokenization",
                    description: "Tokenize real estate assets and properties, enabling fractional ownership and trading on blockchain.",
                    imageUrl: RealEstate,
                     buttonText: "Explore NFTs"
                }
            ]
        },
        servicesOffered: {
            servicesOfferedTitle: "NFT Development",
            servicesOfferedPara: "Our NFT development services combine blockchain technology with creative innovation to offer secure and scalable solutions for tokenizing assets, digital art, collectibles, and more.",
            servicesOfferedData: [
                {
                    title: "Digital Asset Tokenization",
                    icon: "🔗",
                    description: "Tokenize digital assets such as art, music, videos, and virtual goods as Non-Fungible Tokens (NFTs)."
                },
                {
                    title: "NFT Marketplace Development",
                    icon: "🛒",
                    description: "Build decentralized marketplaces for buying, selling, and trading NFTs securely and transparently."
                },
                {
                    title: "Blockchain Integration",
                    icon: "🔗",
                    description: "Integrate blockchain technology to authenticate ownership, ensure provenance, and enable transparent transactions."
                },
                {
                    title: "Smart Contract Development",
                    icon: "📜",
                    description: "Develop and audit smart contracts to automate NFT transactions and enforce ownership rights."
                },
                {
                    title: "NFT Gaming Solutions",
                    icon: "🎮",
                    description: "Create NFT-based gaming solutions with tokenized assets, in-game items, and digital collectibles."
                },
                {
                    title: "Legal and Compliance",
                    icon: "⚖️",
                    description: "Ensure legal compliance and regulatory requirements for NFTs, including IP rights and asset ownership."
                },
                {
                    title: "NFT Consulting Services",
                    icon: "🔍",
                    description: "Provide strategic consulting to explore NFT use cases, tokenomics, and blockchain technology."
                }
            ]
        },
        techStack: {
            serviceName: "NFT Development",
            techdesc: "Our NFT developers leverage leading blockchain platforms and tools to create scalable, secure, and innovative NFT solutions.",
            technologiesUsed: [
                {
                    id: "1",
                    thumbnail: ethereum
                },
                {
                    id: "2",
                    thumbnail: binanceSmartChain
                },
                {
                    id: "3",
                    thumbnail: solana
                },
                {
                    id: "4",
                    thumbnail: tezos
                },
                {
                    id: "5",
                    thumbnail: tensorflow
                },
                {
                    id: "6",
                    thumbnail: polygon
                }
            ],            
        },
        useCasesData: {
            title: "NFT Applications",
            forCustomers: {
                description: "NFT applications for creators and collectors",
                examples: [
                    "Digital art ownership",
                    "Collectible items and memorabilia",
                    "Virtual real estate",
                    "and more"
                ]
            },
            forEmployees: {
                description: "NFT applications for businesses",
                examples: [
                    "Intellectual property rights",
                    "Supply chain provenance",
                    "Ticketing and event management",
                    "and more"
                ]
            }
        },
        process: {
            shortTitle: "NFT",
            mainTitle: "Development Process",
            desc: "Our NFT development process focuses on creating unique and secure solutions that empower creators, artists, and businesses to leverage blockchain technology for tokenizing digital assets.",
            steps: [
                {
                    number: 1,
                    title: 'Conceptualization and Planning',
                    description: 'Define NFT use cases, identify digital assets for tokenization, and plan the development roadmap.'
                },
                {
                    number: 2,
                    title: 'Smart Contract Development',
                    description: 'Design and develop smart contracts to tokenize digital assets, manage ownership, and enable transactions.'
                },
                {
                    number: 3,
                    title: 'NFT Marketplace Integration',
                    description: 'Integrate with decentralized marketplaces or develop custom platforms for buying, selling, and trading NFTs.'
                },
                {
                    number: 4,
                    title: 'Legal and Compliance',
                    description: 'Ensure compliance with legal regulations, intellectual property rights, and blockchain governance.'
                }
            ],
        },
        costFactors: {
            serviceName: "NFT Development",
            costDesc: "The cost of NFT development depends on factors such as blockchain platform, smart contract complexity, marketplace features, legal considerations, and ongoing maintenance.",
            costFactorsItems: [
                {
                    title: "Blockchain Platform",
                    icon: "🔗",
                    description: "The choice of blockchain network (Ethereum, Binance Smart Chain, Solana, etc.) and associated transaction fees."
                },
                {
                    title: "Smart Contract Complexity",
                    icon: "📜",
                    description: "The complexity and functionality of smart contracts governing NFT creation, ownership, and transactions."
                },
                {
                    title: "Marketplace Features",
                    icon: "🛒",
                    description: "The features and functionality required in the NFT marketplace, such as auctions, royalties, and community features."
                },
                {
                    title: "Legal and IP Considerations",
                    icon: "⚖️",
                    description: "Legal fees, intellectual property rights, licensing, and compliance with regulatory frameworks."
                },
                {
                    title: "NFT Development Team",
                    icon: "👨‍💻",
                    description: "The expertise and experience of NFT developers, blockchain architects, and smart contract auditors."
                }
            ]
        },
        faqs: [
            {
                question: "What blockchain platforms do you use for NFT development?",
                answer: "We work with various blockchain platforms, including Ethereum, Binance Smart Chain, Solana, Tezos, Flow, and Polygon. Our goal is to choose the right platform based on your project requirements and ensure optimal performance and security."
            },
            {
                question: "How do you ensure the security of NFTs?",
                answer: "We implement best practices for smart contract development and conduct thorough audits to ensure the security of NFTs. Additionally, we use blockchain technology to authenticate ownership, ensure provenance, and prevent counterfeiting."
            },
            {
                question: "What is the typical timeline for NFT development?",
                answer: "The timeline for NFT development varies depending on the project's complexity and requirements. Generally, it can take anywhere from a few weeks to several months. We provide detailed timelines and milestones to keep you informed throughout the development process."
            },
            {
                question: "Can you help with marketing and launching NFTs?",
                answer: "Yes, we offer comprehensive support for NFT marketing and launch. Our services include strategic planning, community engagement, social media marketing, and collaboration with influencers to ensure a successful NFT launch."
            },
            {
                question: "What are the costs associated with NFT development?",
                answer: "The cost of NFT development depends on various factors, including the blockchain platform, smart contract complexity, marketplace features, legal considerations, and ongoing maintenance. We provide detailed cost estimates based on your specific project requirements."
            },
            {
                question: "Do you offer post-launch support for NFT projects?",
                answer: "Yes, we provide post-launch support to ensure the smooth functioning of your NFT project. Our support services include monitoring, maintenance, updates, and addressing any issues that may arise after the launch."
            }
        ],
        footer:{
            ftitle:"next NFT development project with us?",
         desc:"Reach out to us today and let's discuss how We an help you achieve your goals.",
        }
    },    
}
