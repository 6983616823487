import arrowExplain1 from "../assets/arrowProfessional.svg";
import arrowExplain2 from "../assets/arrowShowcase.svg";
import BorderMagicButton from "./ui/magic-buttton";
import { IoMdSend } from "react-icons/io";
import bussiness2 from "../assets/buss3.webp";
import bussiness4 from "../assets/buss4.webp";
import Clients from "./Clients";


export const Feature = () => {
  return (
    <>
     <div className="flex text-white-100 justify-center md:px-20 lg:my-20 md:gap-8 px-6 py-4 lg:py-0  items-center flex-col">

    </div>
    <Clients/>
      <div className="flex text-white-100 justify-center md:px-20 lg:my-20 sm:mt-20 md:gap-8 px-6 py-4 lg:py-0  items-center flex-col">
        <Explain2 />
              </div>
</>
  );
};
export const Explain2 = () => {
  return (
    <>
      <div className="mx-auto max-w-7xl px-6 flex flex-col-reverse lg:flex-row-reverse justify-center   items-center   lg:justify-between lg:items-center lg:px-16">
        <div className="w-full lg:w-7/12 pb-12 lg:pb-0 relative  flex-col h-full flex lg:justify-start justify-center ite`ms-center lg:text-left text-center lg:items-start  pt-10">
          <h1 className="text-xl md:text-2xl tracking-wide lg:text-2xl text-black-100 px-2 py-1  md:px-3 md:py-2 lg:leading-none lg:w-12/12 lg:ml-36 font-medium bg-blue-400 rounded-sm">
            Together, We Innovate
          </h1>
          <h1 className="text-3xl md:text-4xl my-4 tracking-wider lg:tracking-wide lg:text-left text-center lg:text-4xl lg:pl-36 dark:text-white-100 text-[#333333] lg:leading-tight w-full font-bold">
            Empower Your Business with VirtueTech
          </h1>
          <p className="lg:pl-36 mx-auto w-full lg:mb-10 lg:text-left  tracking-widest lg:tracking-wider text-center dark:text-white-200 text-[#333333] text-base md:text-xl leading-6 lg:leading-7 dark:prose-invert">
            Join our global network to unlock innovative solutions and
            unparalleled support.
          </p>
          <div className="lg:ml-36  w-full lg:w-4/12 lg:mt-0 mt-5">
            <a href="https://wa.me/message/MIAXWLRFXA3HO1" target="_blank">
              <BorderMagicButton
                title="Connect"
                icon={<IoMdSend />}
                position="right"
                otherClasses="w-full md:2xl mt-0 md:mt-0 text-lg sm:text-xl font-normal"
              />
            </a>
          </div>
          <img
            className="w-28 hidden lg:block  rotate-12 dark:invert"
            src={arrowExplain1}
            alt={arrowExplain1}
          ></img>
        </div>
        <div className="h-full flex lg:justify-start justify-center items-center relative  md:w-6/12 sm:w-7/12 w-10/12 lg:w-6/12">
          <img
            className="w-full prop ease-in-out"
            alt={bussiness2}
            src={bussiness2}
          ></img>
        </div>
      </div>
    </>
  );
};
// export const Explain1 = () => {
//   return (
//     <>
//       <div className="mx-auto max-w-7xl  px-6 flex flex-col-reverse lg:flex-row justify-center  items-center  lg:py-0 lg:justify-between lg:items-center lg:px-16">
//         <div className="w-full lg:w-6/12 pb-12 lg:pb-0 relative  flex-col h-full flex lg:justify-start justify-center items-center lg:text-left text-center lg:items-start  pt-10">
//           <h1 className="text-xl md:text-2xl tracking-wide lg:text-2xl text-black-100 px-2 py-1  md:px-3 md:py-2 lg:leading-none lg:w-12/12 font-medium bg-blue-400 rounded-sm">
//           Customer Satisfaction
//           </h1>
//           <h1 className="text-3xl md:text-4xl my-4 tracking-wider lg:tracking-wide lg:text-left text-center lg:text-4xl  dark:text-white-100 text-[#333333] lg:leading-tight w-full font-bold">
//           Simplifying Complexity with Technology          </h1>
//           <p className="mx-auto w-full lg:mb-10 lg:text-left  tracking-widest lg:tracking-wider text-center dark:text-white-200 text-[#333333] text-base md:text-xl leading-6 lg:leading-7 dark:prose-invert">
//           At VirtueTech, we deliver user-friendly solutions to complex problems using cutting-edge technology.
//           </p>
//           <div className=" w-full lg:w-4/12 lg:mt-0 mt-5">
//             <a href="https://wa.me/message/MIAXWLRFXA3HO1" target="_blank">
//               <BorderMagicButton
//                 title="Explore"
//                 icon={<IoMdSend />}
//                 position="right"
//                 otherClasses="w-full md:2xl mt-0 md:mt-0 text-lg sm:text-xl font-normal"
//               />
//             </a>
//           </div>
//           <img
//             className="w-36 hidden lg:block  ml-[500px] dark:invert"
//             src={arrowExplain2}
//             alt={arrowExplain2}
//           ></img>
//         </div>
//         <div className="h-full flex lg:justify-start justify-center items-center relative  md:w-6/12 sm:w-7/12 w-10/12 lg:w-6/12">
//           <img
//             className="w-full prop ease-in-out"
//             alt={bussiness4}
//             src={bussiness4}
//           ></img>
//         </div>
//       </div>
//     </>
//   );
// };

