import React, { useState, useEffect } from 'react'
import {  FaMinus, FaPlus } from 'react-icons/fa6';

const Accordion = ({ accordionData }) => {
  const [openAccordions, setOpenAccordions] = useState([]);

  useEffect(() => {
    // Initialize openAccordions state with all false values
    setOpenAccordions(Array(accordionData.length).fill(false));
  }, [accordionData]);

  const handleToggle = (index) => {
    setOpenAccordions((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };
  return (
    <div className='w-full '>
      {accordionData?.map((item, index) => (
        <div key={index} className="flex flex-col element my-4 w-full justify-start items-center">
          <div
            className="flex transition-all element bg-blue-900 bg-opacity-5 hover:dark:bg-blue-800 hover:bg-blue-500 hover:bg-opacity-40  border border-opacity-65 shadow-md shadow-blue-900 border-blue-400    dark:text-white w-full lg:py-8 md:py-4 py-3 px-3 md:px-4 my-2 lg:px-6 text-sm lg:text-lg md:text-base font-normal justify-between items-center flex-row lg:tracking-wide tracking-wider cursor-pointer"
            onClick={() => handleToggle(index)}
          >
            <h2 className="md:tracking-wide tracking-widest  w-10/12">
              {index + 1}) {item?.question}
            </h2>
            {openAccordions[index] ? (
              <FaMinus className="font-bold element text-2xl md:text-3xl dark:text-blue-400 text-blue-600" />
            ) : (
              <FaPlus className="font-bold element text-2xl md:text-3xl dark:text-blue-400 text-blue-600" />
            )}
          </div>
          {openAccordions[index] && (
            <div className="w-full element duration-300 lg:p-8 md:p-6 p-4 bg-opacity-5  bg-blue-900">
              <div className="flex  dark:border-white border-opacity-40  flex-col gap-1 lg:gap-3">
                <p className="lg:text-lg md:text-base text-sm lg:tracking-wide opacity-80 tracking-widest text-gray-900 dark:text-gray-200 md:font-normal font-extralight ">
                  {item?.answer}
                </p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default Accordion;
