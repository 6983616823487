import React from "react";
import FusionTech from "../assets/Clients Logo/Fusion Tech.png"
import ORL from "../assets/Clients Logo/ORL.png"
import Kiwi from "../assets/Clients Logo/Kiwi New Energy.png"
import Formosa from "../assets/Clients Logo/Formosa.png"
import futurelocus from "../assets/Clients Logo/futurelocus.png"
import SelfieSign from "../assets/Clients Logo/SelfieSign logo_HD.png"
import ThinkCloud from "../assets/Clients Logo/ThinkCloud雲想科技_橫式Logo_彩色黑字_版本1.png"
import { InfiniteCompany } from "./ui/infinite-company";

const company = [
  {
    id: "2",
    thumbnail: FusionTech,
  },
  {
    id: "3",
    thumbnail: ORL,
  },
  {
    id: "4",
    thumbnail: Kiwi,
  },
  {
    id: "2",
    thumbnail: Formosa,
  },
  {
    id: "3",
    thumbnail: futurelocus,
  },
  {
    id: "4",
    thumbnail: SelfieSign,
  },
  {
    id: "7",
    thumbnail: ThinkCloud,
  },
 
];

const Clients = () => {
  return (
    <div className="md:px-20 flex-col gap-8 px-8 flex justify-center items-center w-12/12 ">
      <InfiniteCompany
        items={company}
        direction="right"
        speed="slow"
        className="w-full py-4"
      />

      <div className="flex justify-evenly gap-10 lg:mt-20 my-8 md:mt-14 flex-col sm:flex-row w-full  rounded-2xl items-center">
        <div className="flex-col relative  flex justify-center items-center">
          <p className="absolute text-5xl dark:text-blue-400 text-[#1976D2]  -top-3 font-black -right-6  md:-right-4">
            +
          </p>
          <h1 className="text-7xl dark:text-blue-400 text-[#1976D2]  font-bold">
            4
          </h1>
          <p className="md:font-bold font-medium tracking-wider md:tracking-wide  text-[#333333] dark:text-white-100 text-lg md:text-2xl pt-0.5 md:pt-2">
            Years
          </p>
        </div>
        <div className="flex-col relative  flex justify-center items-center">
          <p className="absolute dark:text-blue-400 text-[#1976D2]  text-5xl -top-3 font-black -right-2 md:right-3"
>+          </p>
          <h1 className="text-7xl dark:text-blue-400 text-[#1976D2]  font-bold">
            25
          </h1>
          <p className="md:font-bold font-medium tracking-wider md:tracking-wide  text-[#333333] dark:text-white-100 text-lg md:text-2xl pt-0.5 md:pt-2">
            Happy Clients
          </p>
        </div>
        <div className="flex-col relative  flex justify-center items-center">
          <p className="absolute dark:text-blue-400 text-[#1976D2]  text-5xl -top-3 font-black -right-8 md:-right-6">
            +
          </p>
          <h1 className="text-7xl dark:text-blue-400 text-[#1976D2]  font-bold">
            50
          </h1>
          <p className="md:font-bold font-medium tracking-wider md:tracking-wide  text-[#333333] dark:text-white-100 text-lg md:text-2xl pt-0.5 md:pt-2">
            Projects
          </p>
        </div>
      </div>
    </div>
  );
};

export default Clients;
