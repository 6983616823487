import React from "react";
import js from "../assets/Lang/js.webp";
import flutter from "../assets/Lang/Flutter.png";
import Kotlin from "../assets/Lang/Kotlin.webp";
import node from "../assets/Lang/NodeJS.png";
import php from "../assets/Lang/PHP.png";
import native from "../assets/Lang/React-Native.png";
import swift from "../assets/Lang/Swift.png";
import angular from "../assets/Lang/angular.webp";
import java from "../assets/Lang/java.webp";
import mongo from "../assets/Lang/mongoDb.webp";
import python from "../assets/Lang/python.webp";
import redis from "../assets/Lang/redis.webp";
import sql from "../assets/Lang/sql.webp";
import typescript from "../assets/Lang/type.webp";
import tailwind from "../assets/Lang/tailwind.png";
import dotnet from "../assets/Lang/dotnet.svg";
import amqp from "../assets/Lang/amqp.png";
import arduino from "../assets/Lang/arduino.png";
import aws from "../assets/Lang/aws.webp";
import azureIotHub from "../assets/Lang/azure-iot-hub.png";
import bigml from "../assets/Lang/bigml.png";
import binanceSmartChain from "../assets/Lang/binance-smart-chain.png";
import chainlink from "../assets/Lang/Chainlink.png";
import corda from "../assets/Lang/corda.png";
import cosmos from "../assets/Lang/cosmos.png";
import ethereum from "../assets/Lang/Ethereum.png";
import firebase from "../assets/Lang/firebase.png";
import ganache from "../assets/Lang/ganache.png";
import huggingFace from "../assets/Lang/hugging_face-icon.webp";
import hyperledger from "../assets/Lang/hyperledger.png";
import iconic from "../assets/Lang/iconic.webp";
import iotLora from "../assets/Lang/iot-lora.png";
import ipfs from "../assets/Lang/Ipfs.png";
import mqtt from "../assets/Lang/mqtt.png";
import keras from "../assets/Lang/keras.png";
import jupyter from "../assets/Lang/jupyter.png";
import matlab from "../assets/Lang/Matlab.png";
import raspberryPi from "../assets/Lang/raspberry-pi.png";
import nodeRed from "../assets/Lang/node-red.webp";
import opencv from "../assets/Lang/opencv.png";
import phonegap from "../assets/Lang/phonegap.png";
import polygon from "../assets/Lang/polygon.png";
import pytorch from "../assets/Lang/pytorch";
import solana from "../assets/Lang/solana-sol.png";
import solidity from "../assets/Lang/solidity.png";
import tensorflow from "../assets/Lang/tensorflow.png";
import tezos from "../assets/Lang/tezos.webp";
import thingsboard from "../assets/Lang/thingsboard.png";
import transferData from "../assets/Lang/transfer_data.webp";
import truffle from "../assets/Lang/truffle.png";
import web3js from "../assets/Lang/web3js.png";
import zigbee from "../assets/Lang/zigbee.png";
import azure from "../assets/Lang/microsoft-azure.png";
import googleCloud from "../assets/Lang/gcloud.webp";
import xamarin from "../assets/Lang/xamarin.png";
import scikitLearn from "../assets/Lang/scikit-learn.png";
import Heading from "./ui/Heading";
import Paragraph from "./ui/Paragraph";
import { InfiniteStack } from "./ui/infinite-stack";

const Stack = () => {
  const stack1 = [
    {
      id: "2",
      thumbnail: js,
    },
    {
      id: "3",
      thumbnail: typescript,
    },
    {
      id: "4",
      thumbnail: angular,
    },
    {
      id: "5",
      thumbnail: java,
    },
    {
      id: "6",
      thumbnail: Kotlin,
    },
    {
      id: "7",
      thumbnail: swift,
    },
    {
      id: "8",
      thumbnail: python,
    },
    {
      id: "9",
      thumbnail: native,
    },
    {
      id: "10",
      thumbnail: node,
    },
    {
      id: "11",
      thumbnail: flutter,
    },
    {
      id: "32",
      thumbnail: iconic,
    },
    {
      id: "33",
      thumbnail: iotLora,
    },
    {
      id: "34",
      thumbnail: ipfs,
    },
    {
      id: "35",
      thumbnail: mqtt,
    },
    {
      id: "36",
      thumbnail: keras,
    },
    {
      id: "37",
      thumbnail: jupyter,
    },
    {
      id: "38",
      thumbnail: matlab,
    },
    {
      id: "39",
      thumbnail: raspberryPi,
    },
    {
      id: "40",
      thumbnail: nodeRed,
    },
  ] 
  const stack2 = [
    {
      id: "12",
      thumbnail: php,
    },
    {
      id: "13",
      thumbnail: mongo,
    },
    {
      id: "14",
      thumbnail: redis,
    },
    {
      id: "15",
      thumbnail: sql,
    },
    {
      id: "16",
      thumbnail: tailwind,
    },
    {
      id: "17",
      thumbnail: dotnet,
    },
    {
      id: "18",
      thumbnail: amqp,
    },
    {
      id: "19",
      thumbnail: arduino,
    },
    {
      id: "20",
      thumbnail: aws,
    },
    {
      id: "41",
      thumbnail: opencv,
    },
    {
      id: "42",
      thumbnail: phonegap,
    },
    {
      id: "43",
      thumbnail: polygon,
    },
    {
      id: "44",
      thumbnail: pytorch,
    },
    {
      id: "45",
      thumbnail: scikitLearn,
    },
    {
      id: "46",
      thumbnail: solana,
    },
    {
      id: "47",
      thumbnail: solidity,
    },
    {
      id: "48",
      thumbnail: tensorflow,
    },
  
  ]
  const stack3= [
    {
      id: "21",
      thumbnail: azureIotHub,
    },
    {
      id: "22",
      thumbnail: bigml,
    },
    {
      id: "23",
      thumbnail: binanceSmartChain,
    },
    {
      id: "24",
      thumbnail: chainlink,
    },
    {
      id: "25",
      thumbnail: corda,
    },
    {
      id: "26",
      thumbnail: cosmos,
    },
    {
      id: "27",
      thumbnail: ethereum,
    },
    {
      id: "28",
      thumbnail: firebase,
    },
    {
      id: "29",
      thumbnail: ganache,
    },
    {
      id: "30",
      thumbnail: huggingFace,
    },
    {
      id: "31",
      thumbnail: hyperledger,
    },
    {
      id: "49",
      thumbnail: tezos,
    },
    {
      id: "50",
      thumbnail: thingsboard,
    },
    {
      id: "51",
      thumbnail: transferData,
    },
    {
      id: "52",
      thumbnail: truffle,
    },
    {
      id: "53",
      thumbnail: web3js,
    },
    {
      id: "54",
      thumbnail: zigbee,
    },
    {
      id: "55",
      thumbnail: azure,
    },
    {
      id: "56",
      thumbnail: googleCloud,
    },
    {
      id: "57",
      thumbnail: xamarin,
    },
  

  ]
    
 
  return (
    <div className="flex justify-center md:px-20 my-6  sm:mb-12 px-6  items-center flex-col">
      <div className="flex justify-center mb-6 items-center flex-col ">
        <Heading first={""} second={"Technology"} third={"Stack"} />
        <Paragraph
          para={
            "  Our engineers apprehend your business requirements and help you choose  the right technology for your solution."
          }
        />
      </div>

      <div className="flex justify-center items-center flex-col overflow-hidden  md:gap-0 gap-1.5 w-full md:px-4 px-2 md:w-11/12">
      <div className="flex justify-center h-[5rem] lg:h-[8rem] md:h-[7rem] w-full  items-center flex-row flex-wrap">
          <InfiniteStack
            items={stack1}
            direction="right"
            speed="slow"
            className=""
          />
        </div>
        <div className="flex justify-center h-[5rem] lg:h-[8rem] md:h-[7rem] w-full  items-center flex-row flex-wrap">
          <InfiniteStack
            items={stack2}
            direction="left"
            speed="fast"
            className=""
          />
        </div>
        <div className="flex justify-center h-[5rem]  lg:h-[8rem] md:h-[7rem] w-full  items-center flex-row flex-wrap">
          <InfiniteStack
            items={stack3}
            direction="right"
            speed="normal"
            className=""
          />
        </div>
      </div>
    </div>
  );
};

export default Stack;
